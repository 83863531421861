import React, { useEffect, useState } from 'react';
import NavBar from '../Navigation/NavBar';
import Footer from '../pages/LandingPage/Footer';
import { TextField, useMediaQuery } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import CallIcon from '@mui/icons-material/Call';
import imgIndia from '../../assets/washington-dc-silhouette-panorama 1.jpg';
import school from '../../assets/School Building (1).jpg';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import PaginationItem from '@mui/material/PaginationItem';
import { Dialog, CircularProgress,  Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router-dom';

function TeacherJobs() {
  const location = useLocation();
  const isXs = useMediaQuery('(min-width:600px) and (max-width:3000px)');
  const [data, setData] = useState([]);
  const [nextPage, SetNextPage] = useState();
  const [priviousPage, setPriviousPage ] = useState();
  const [pageNumber, setPageNumber] = useState('')
  const [to, setTo] = useState('')
  const [total, setTotal] = useState('')
  const [lastPage,setLastPage]= useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [otpNew, setOtpNew] = useState("");
  const [smsIdNew, setSmsIdNew] = useState(null);   
  const [otpSent, setOtpSent] = useState(false);
  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [city, setCity]= useState('')
  const [pincode , setPincode] = useState('')
  const [pdf, setPdf] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [salary, setSalary] = useState('');
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [jobTypes, setJobTypes] = useState({});

    useEffect(() => {
      window.scrollTo(0, 0);
      }, [location]); 

  const handleChangeOtpNew = (NewValue) => {
    setOtpNew(NewValue);
  };

let payload = { paginate: '1' ,
    pageCount:'10',
  };
  const TeacherData = async (abc,payload) => {

    try {
      const response = await axios.get(abc, { params: payload });
      setData(response.data.data);
      const NextPage = response.data.links.next
      const pageNumber = response.data.meta.from
      const toPage =  response.data.meta.to
      const total =  response.data.meta.total
      const LastPage =  response.data.meta.lastPage
      setLastPage(LastPage)
      setTotal(total);
      setTo(toPage)
      setPageNumber(pageNumber)
      SetNextPage(NextPage);
      const PriviousPage = response.data.links.prev
      setPriviousPage(PriviousPage);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    TeacherData("https://app.kanhahometutions.com/api/v1/demoRequestInstitutes",payload);
  }, []);

  const CapitalizedText = ({ text }) => {
    const capitalizeFirstLetter = (str) => {
      return str
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    };

    return <p>{capitalizeFirstLetter(text)}</p>;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleClickOpenNew = () => {
    setOpenNew(true);
  };
  const handleCloseNew = () => {
    setOpenNew(false);
  };

 

  const handlejobsubmit = async(e)  =>{
   e.preventDefault()
   
   const formDatanew = new FormData();
   formDatanew.append("fullName", name);
   formDatanew.append("email", email);
   formDatanew.append('mobile', mobile);
   formDatanew.append('city', city);
   formDatanew.append('pincode', pincode);   
   formDatanew.append('token', "abcd1234");
   formDatanew.append('smsType',"jobSmApply");
   formDatanew.append('cvFile', pdf);   

   try {
    const response = await axios.post("https://app.kanhahometutions.com/api/v2/notifySMS", formDatanew);
    if (response.data.success === true) {
      toast.success(response.data.message);
      setSmsIdNew(response.data.data.smsID.toString()); 
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setOtpSent(true);
      }, 1000);
    } else {
      const message = response.data.message || 'An error occurred'; 
      toast.error(message);
    }
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    toast.error(errorMessage || 'Something went wrong');
  }
  }

  const handleVerifyOtp = async(e)=> {
    e.preventDefault();
    
      const formDatanew = new FormData();
      formDatanew.append("fullName", name);
      formDatanew.append("email", email);
      formDatanew.append('mobile', mobile);
      formDatanew.append('city', city);
      formDatanew.append('pincode', pincode);   
      formDatanew.append('token', "abcd1234");
      formDatanew.append('smsType',"jobSmApply");
      formDatanew.append('cvFile', pdf); 
      formDatanew.append("smsId", smsIdNew);
      formDatanew.append("smsOtp", otpNew);

      try {
        const response = await axios.post("https://app.kanhahometutions.com/api/v2/verifyOtp", formDatanew);
        if (response.data.success.toString() === "true") {
          toast.success(response.data.message);
            setCity('')
            setEmail('')
            setOtpNew('')
            setOpen(false)
            setOtpSent(false);

        } else {
          toast.error(response.data.message ?? "OTP does not match.");
        }
      } catch (error) {
        toast.error(error?.response?.data.message);
      }
    }

const handleResendOtp = async(e) => {
e.preventDefault();
setIsLoading(true);

  const formdata = new FormData();
  formdata.append("smsId", smsIdNew);
  formdata.append("mobile", mobile );
  formdata.append("smsType","jobSmApply" );
  formdata.append("token", 'abcd1234');

  try {
    const response = await axios.post('https://app.kanhahometutions.com/api/v2/resendOTP',formdata);
    if (response.data.success.toString() === "true") {
      setSmsIdNew(response.data.data.smsID.toString());
      toast.success(response.data.message);
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);

    } else {
      toast.error(response.data.message);
    }
  } catch (error) {}
};


  const handlesubmitmessage = async (e) => {
    e.preventDefault();

    let date = new Date();
    let randomnumber = Math.floor((Math.random() * 1000000));
    let CurrentDateTime = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}${randomnumber}${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`;
    const formDatanew = new FormData();
    formDatanew.append("fullName", name);
    formDatanew.append("email", email);
    formDatanew.append('phone', mobile);
    formDatanew.append('description', description);
    formDatanew.append('token', CurrentDateTime);

    try {
      const response = await axios.post("https://app.kanhahometutions.com/api/v1/contact", formDatanew);
      if (response.data.success === true) {
        setName('');
        setEmail('');
        setMobile('');
        setDescription('');
        setIsLoading(false)
        setTimeout(() => {
          setOpenNew(false)
          setIsLoading(true)
          toast.success(response.data.message);
        }, 1000);
       
      } else {
        const message = response.data.message || 'An error occurred'; 
        toast.error(message);
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      toast.error(errorMessage || 'Something went wrong');
    }}

   const handleSalary = (event) => {
    setSalary(event.target.value);
   };
 
   const handleMinChange = (e) => {
     setMinValue(e.target.value);
   };
 
   const handleMaxChange = (e) => {
     setMaxValue(e.target.value);
   };

   const handleSubmit = (e)=> {
    e.preventDefault();

    const jobType = [];
    if (formState.fullTime) jobType.push('Full Time');
    if (formState.partTime) jobType.push('Part Time');
    if (formState.onlineTeaching) jobType.push('Online Teaching');
    
    const payload = {
      paginate: '1',
      pageCount: '10',
      jobType: jobType.join(','),
      minSalary: minValue,
      maxSalary: maxValue,
      salaryType:salary=="None" ? "" : salary,
    };

    TeacherData("https://app.kanhahometutions.com/api/v1/demoRequestInstitutes",payload)
   }
   const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const [formState, setFormState] = useState({
    fullTime: false,
    partTime: false,
    onlineTeaching: false,
  });

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClick = () => {

    const jobType = [];
    if (formState.fullTime) jobType.push('Full Time');
    if (formState.partTime) jobType.push('Part Time');
    if (formState.onlineTeaching) jobType.push('Online Teaching');

    const payload = {
      paginate: '1',
      pageCount: '10',
      jobType: jobType.join(','),
      minSalary: minValue,
      maxSalary: maxValue,
      search: searchTerm,
    };
    TeacherData("https://app.kanhahometutions.com/api/v1/demoRequestInstitutes",payload)
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleContent = () => {
    setIsOpen(!isOpen);
  };
 
  return (
    <>
      <NavBar />
      <div style={{ backgroundColor: '#CB8B65', display: 'flex', alignItems: 'center', paddingLeft: isXs ? '6rem' : '1rem', gap: '10px', height: '50px' }}>
        <div><a style={{ color: 'white', textDecoration: 'none', fontSize: '1rem' }} href="/">Home</a></div>
        <ArrowForwardIosIcon style={{ fontSize: '14px', color: 'white' }} />
        <div style={{ fontSize: '1rem ', color: 'white' }}>Teacher Jobs</div>
      </div>

      <div style={{ width: '100%', height: '250px' }}>
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
          <img src={imgIndia} alt="India" style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: 0, left: 0, zIndex: 1 }} />
          <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(85, 85, 85, 0.5)', zIndex: 2 }}></div>
          <div style={{ position: 'absolute', zIndex: 3, color: 'white', padding: '20px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column' }}>
            <div style={{ width: isXs? "936px":'', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: isXs ? '20px':"20px", alignItems: 'center' }}>
              <div style={{ fontSize: isXs? '30px':'17px' }}>School & Teachers Jobs Available in India</div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <input
                  style={{ fontSize: '16px', height: '40px', width: isXs? '490px':'280px', paddingLeft: '10px', border: '0px', color: 'black' }}
                  type="text"
                  placeholder="Job search"
                  onChange={handleInputChange}
                />
                <div
                  style={{ fontSize: isXs ?'16px':'14px', textAlign: 'center', color: 'black', height: '40px', padding: isXs ? '8px 20px':"10px 10px", color: 'white', cursor: 'pointer', backgroundColor: '#CB8B65' }}
                  onClick={handleSearchClick} 
                >
                  Search
                </div>
              </div>
              <div  style={{
                          backgroundColor: "#FFDB7E",
                          border: "none",
                          display:'flex',
                          color: "#866D2C",
                          borderRadius: "5px",
                          fontWeight: "bold",
                          fontSize: isXs ? "1.5rem" : "1rem",
                          height: isXs ? "3rem" : "3rem",
                          alignItems:'center',
                          boxShadow: "5px  5px 2px rgb(0,0,0, .2)",
                          padding:'10px 30px'
                        }}>
          <a
            style={{ textDecoration: "none", color: "#866D2C" }}
            href="/post-teacher-job"
          >
            Post a Teacher Job 
          </a>
        </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ width: '100%', display: 'flex', gap: '1.5rem', backgroundColor: 'white', padding: isXs ?"50px 80px":"20px 10px" }}>
        <div style={{ width: isXs ? '75% ':'100%', border: '1px solid #edeaea', height: 'auto'}}>
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: isXs ?'24px 24px':'14px 4px', border: '1px solid #edeaea', height: '62px', alignItems: 'center', padding: '0px 20px' }}>
            <div>Showing {pageNumber} to {to} of {total} entries</div>
          </div>


       

          {data.map((item, index) => (
  <div key={index} style={{ border: isXs ? '1px solid #edeaea' : '1px solid rgb(165, 165, 165)', height: isXs ? '212px' : '240px', margin: isXs ? '24px 24px' : '14px 4px', display: 'flex' }}>
    <div style={{ height: '212px', width: isXs ? '35%' : '0%' }}>
      <img src={school} style={{ height: '100%', width: isXs ? '100%' : '0px', objectFit: 'cover', display: isXs ? 'flex' : 'none' }} alt="" />
    </div>
    <div style={{ height: '152px', width: isXs ? '65%' : '100%' }}>
      <div style={{ gap: '7px', display: 'flex', flexDirection: 'column', padding: isXs ? '15px 20px 15px 20px' : '15px 20px 15px 20px' }}>
        <div style={{ fontSize: '14px', display: 'flex', justifyContent: 'space-between' }}>
          <div>{item.jobType}</div>
          <div>
            <div style={{ display: 'flex', gap: '10px' }}>
              <div onClick={handleClickOpen} style={{ backgroundColor: '#CB8B65', color: 'white', padding: '3px 10px', borderRadius: '5px', cursor: 'pointer' }}>Apply</div>
              <div onClick={handleClickOpenNew} style={{ backgroundColor: '#f79760', color: 'white', padding: '3px 10px', borderRadius: '5px', cursor: 'pointer' }}>
                <CallIcon style={{ fontSize: '16px' }} /> Contact
              </div>
            </div>
          </div>
        </div>
        <div style={{ fontSize: isXs ? '20px' : "18px" }}><CapitalizedText text={item.jobTitle} /></div>
        <div style={{ fontSize: '14px', height: '65px' }}>
          <div style={{ width: isXs?  '460px':'100%', height: '78px', overflow: 'hidden' }}>
            <iframe
              srcDoc={`
                <head>
                  <link href="https://fonts.googleapis.com/css2?family=Jost:wght@400;600&display=swap" rel="stylesheet">
                </head>
                <body style="margin: 0;">
                  <div style="height: 150px; font-size: 14px; font-family: Jost, sans-serif; color:#595656; line-height: 1.5; letter-spacing: 1px;">
                    ${item.description} 
                  </div>
                </body>
              `}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
              }}
              title="Vertical Scroll Iframe"
            />
          </div>
        </div>
      </div>
      <div><hr style={{ margin: '0px', border: '1px solid #edeaea', width: '99.6%' }} /></div>
      <div style={{ display: 'flex', flexDirection: isXs ? 'row' : 'column', justifyContent: 'space-between', alignItems: isXs ? 'center' : 'start', padding: isXs ? '10px 24px' : '10px 24px', gap: isXs ? '0px' : '10px' }}>
        <div style={{ display: 'flex' }}>
          <div>&#8377;{item.minSalary}</div> &nbsp;-&nbsp;
          <div>&#8377;{item.maxSalary} / {item.salaryType}</div>
        </div>

        <div style={{ display: 'flex' }}>
          <div style={{ paddingLeft: isXs ? '0px' : '0px' }}>
            <LocationOnIcon style={{ fontSize: '15px', color: '#CB8B65' }} />
            {item.city}
          </div>
          &nbsp;-&nbsp;
          <div>{item.pincode}</div>
        </div>
      </div>
    </div>
  </div>
))}



<Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
  <div style={{display:'flex', alignItems:'center', justifyContent:'space-between',padding: '10px 20px',}}>
  <div style={{  fontSize: '20px' }}>Apply Jobs</div>
<div onClick={handleClose} style={{cursor:'pointer'}}><CloseIcon style={{fontSize:'14px'}}/></div>
  </div>
  <hr style={{border:'1px solid', margin:'0px 0px 10px 0px', color:'#cec8c8'}} />
  <form onSubmit={handlejobsubmit}>
  <div style={{ margin: '10px 20px', display: 'flex', gap: isXs? '17px':'10px', flexDirection: 'column' }}>

    <div style={{ display: 'flex', flexDirection: isXs ?'row':'column', gap: '20px'}}>
      <div style={{display:'flex', flexDirection: isXs ?'column':'column'}}>
        <label style={{ fontSize: '14px', marginBottom: '5px' }}>Name</label>
        <input style={{ width: isXs ?  '210px' :'100%', paddingLeft: '10px', height: '40px', border:'1px solid #e2e0e0', borderRadius:'5px' }} onChange={(e) => setName(e.target.value)} required type="text" placeholder="Name" />
      </div>
      <div style={{display:'flex', flexDirection: isXs ?'column':'column'}}>
        <label style={{ fontSize: '14px', marginBottom: '5px' }}>Phone Number</label>
        <input style={{  width: isXs ?  '210px' :'100%',paddingLeft: '10px', height: '40px' , border:'1px solid #e2e0e0', borderRadius:'5px' }} onChange={(e) => setMobile(e.target.value)} required type="number" placeholder="Phone Number" />
      </div>
    </div>

    <div style={{ display: 'flex', flexDirection: isXs ?'row':'column', gap: '20px' }}>
      <div style={{display:'flex', flexDirection: isXs ?'column':'column'}}>
        <label style={{ fontSize: '14px', marginBottom: '5px' }}>Email</label>
        <input style={{  width: isXs ?  '210px' :'100%', paddingLeft: '10px', height: '40px', border:'1px solid #e2e0e0', borderRadius:'5px'}} onChange={(e) => setEmail(e.target.value)} required  type="email" placeholder="Enter your Email" />
      </div>
      <div style={{display:'flex', flexDirection: isXs ?'column':'column'}}>
        <label style={{ fontSize: '14px', marginBottom: '5px' }}>City</label>
        <input style={{ width: isXs ?  '210px' :'100%', paddingLeft: '10px', height: '40px', border:'1px solid #e2e0e0', borderRadius:'5px' }} onChange={(e) => setCity(e.target.value)} required type="text" placeholder="City" />
      </div>
    </div>
    <div style={{ display: 'flex', flexDirection: isXs ?'row':'column', gap: '20px' }}>
      <div style={{display:'flex', flexDirection: isXs ?'column':'column'}}>
        <label style={{ fontSize: '14px', marginBottom: '5px' }}>Pin Code</label>
        <input style={{ width: isXs ?  '210px' :'100%', paddingLeft: '10px', height: '40px', border:'1px solid #e2e0e0', borderRadius:'5px' }}  onChange={(e) => setPincode(e.target.value)} required  type="number" placeholder="Enter your Pin Code" />
      </div>
      <div style={{display:'flex', flexDirection: isXs ?'column':'column'}}>
        <label style={{ fontSize: '14px', marginBottom: '5px' }}>Upload CV PDF</label>
        <input style={{ width: isXs ?  '210px' :'100%', height: '40px', marginTop:'5px'}} required type="file"accept="application/pdf" placeholder="Upload PDF"  onChange={(e) => setPdf(e.target.files[0])}/>
      </div>
    </div>
  </div>
  <hr style={{border:'1px solid', margin:'20px 0px 0px 0px', color:'#cec8c8'}} />
 
  <div style={{ margin: '20px 24px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: isXs ?'10px':'20px' }}>
      {/* Show OTP input only after OTP is sent */}
      {otpSent && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '10px' }}>
          <OtpInput
            value={otpNew}
            onChange={handleChangeOtpNew}
            numInputs={6}
            renderSeparator={<span></span>}
            renderInput={(props) => (
              <input
                {...props}
                style={{
                  width: '40px',
                  height: '40px',
                  textAlign: 'center',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  padding: '5px 10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '0px 2px',
                }}
              />
            )}
          />
        </div>
      )}

      {/* Resend OTP option */}
      
      {otpSent && (
        <div style={{ display: 'flex', gap: '10px' }}>
          <div>Didn’t Receive OTP?</div>
          <button      
           disabled={isLoading}
           style={{ cursor: 'pointer', color: 'blue' }} onClick={handleResendOtp}>
             Resend OTP
          </button>
        </div>
      )}

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        {!otpSent ? (          
          <button
            style={{
              fontSize: '16px',
              color: 'white',
              backgroundColor: '#CB8B65',
              borderRadius: '5px',
              width: isXs? '420px':'300px',
              height: '40px',
            }}
            disabled={isLoading}
          onClick={handlejobsubmit}
          >
             {isLoading ? 'Get OTP' : 'Get OTP'}
          </button>
        ) : (
          <button
            style={{
              fontSize: '16px',
              color: 'white',
              backgroundColor: '#CB8B65',
              borderRadius: '5px',
              width: isXs? '420px':'300px',
              height: '40px',
              textAlign: 'center',
              lineHeight: '40px',
              cursor: 'pointer',
            }}
            onClick={handleVerifyOtp}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  </form>
</Dialog>



<Dialog maxWidth="sm" fullWidth open={openNew} onClose={handleCloseNew}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px' }}>
        <div style={{ fontSize: '20px' }}>Send Message</div>
        <div onClick={handleCloseNew} style={{ cursor: 'pointer' }}>
          <CloseIcon style={{ fontSize: '14px' }} />
        </div>
      </div>
      <hr style={{ border: '1px solid', margin: '0px 0px 10px 0px', color: '#cec8c8' }} />
      <form onSubmit={handlesubmitmessage}>
        <div style={{ margin: '10px 20px', display: 'flex', gap: '17px', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input style={{ width: isXs ? '440px' :'100%', paddingLeft: '10px', height: '40px', border: '1px solid #e2e0e0', borderRadius: '5px' }} onChange={(e) => setName(e.target.value)}  required type="text" placeholder="Enter Your Name" />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input style={{  width: isXs ? '440px' :'100%', paddingLeft: '10px', height: '40px', border: '1px solid #e2e0e0', borderRadius: '5px' }} onChange={(e) => setEmail(e.target.value)}  required type="email" placeholder="Email Address" />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input style={{  width: isXs ? '440px' :'100%', paddingLeft: '10px', height: '40px', border: '1px solid #e2e0e0', borderRadius: '5px' }} onChange={(e) => setMobile(e.target.value)}  required type="number" placeholder="Enter Phone Number" />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <textarea style={{ width: isXs ? '440px' :'100%', paddingLeft: '10px', height: '150px', border: '1px solid #e2e0e0', borderRadius: '5px', paddingTop: '7px' }} onChange={(e) => setDescription(e.target.value)}  required placeholder="Message" />
          </div>
        </div>
        <hr style={{ border: '1px solid', margin: '20px 0px 0px 0px', color: '#cec8c8' }} />
        <div style={{ padding: '20px 24px', display: 'flex', justifyContent: 'end', gap: '10px' }}>
          <div
            onClick={handleCloseNew}
            style={{
              fontSize: '16px',
              color: 'white',
              backgroundColor: '#CB8B65',
              padding: '5px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Cancel
          </div>
        
            {isLoading ? (
              <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
                <CircularProgress color="success" size={24} />
              </Stack>
            ) : (
              <button
              type="submit"
              style={{
                fontSize: '16px',
                color: 'white',
                backgroundColor: '#CB8B65',
                padding: '5px 20px',
                borderRadius: '5px',
              }}
            >Send </button> )}
        </div>
      </form>
    </Dialog>



<nav style={{ paddingBottom: isXs ?'24px':'10px', paddingTop:'20px', display:'flex', justifyContent:'center'}} aria-label="Page navigation">
  <Pagination
    count={lastPage}
    onChange={(event, page) => { 

      const jobType = [];
    if (formState.fullTime) jobType.push('Full Time');
    if (formState.partTime) jobType.push('Part Time');
    if (formState.onlineTeaching) jobType.push('Online Teaching');

    const payload = {
      paginate: '1',
      pageCount: '10',
      jobType: jobType.join(','),
      minSalary: minValue,
      maxSalary: maxValue,
      search: searchTerm,
      page:page
    };
    TeacherData("https://app.kanhahometutions.com/api/v1/demoRequestInstitutes",payload)
      // TeacherData(`https://app.kanhahometutions.com/api/v1/demoRequestInstitutes?page=${page}`)
      }}
    renderItem={(item) => (
      <PaginationItem
        style={{ textDecoration:'none' }}
        component="a"
        href="#"
        {...item}
        sx={{
          '& .MuiPaginationItem-root': {
            color: '#d38358',
          },
          '& .MuiPaginationItem-root.Mui-selected': {
            backgroundColor: '#d38358',
            color: '#fff',
          },
          '& .MuiPaginationItem-root:hover': {
            backgroundColor: '#d38358',
          },
        }}
      />
    )}
  />
</nav>

        </div>

        <div style={{ display: isXs ?'flex':'none', width: '25%', border: '1px solid #edeaea', height:'450px' }}>
        <form onSubmit={handleSubmit}>
      <div>
        <div style={{ fontSize: '20px', padding: '10px 24px 0px 24px' }}>Price Range</div>
        <hr style={{ border: '1px solid #edeaea', margin: '10px 0px 0px 0px', color: '#cec8c8' }} />

        <div style={{ margin: '0px 24px 0px 24px' }}>
          <FormControl style={{ width: '100%', marginTop: '20px' }} fullWidth>
            <InputLabel id="salary-label" style={{ fontSize: '12px' }}>Salary</InputLabel>
            <Select
              labelId="salary-label"
              value={salary}
              onChange={handleSalary}
              style={{ height: '45px', fontSize: '12px' }}
              label="Salary"
            >
              <MenuItem value="None">None</MenuItem>
              <MenuItem value="Hour">Hour</MenuItem>  
              <MenuItem value="Week">Week</MenuItem>           
              <MenuItem value="Month">Month</MenuItem>
              <MenuItem value="Year">Year</MenuItem>

            </Select>
          </FormControl>
        </div>

        <div style={{ display: 'flex', gap: '10px', margin: '0px 24px 0px 24px' }}>
          <TextField
            style={{ marginTop: '20px' }}
            label="Min"
            type="number"
            placeholder="₹"
            value={minValue}
            onChange={handleMinChange}
            InputProps={{
              style: { height: '40px', fontSize: '12px' },
              inputProps: {
                style: { fontSize: '12px', alignItems: 'center' }
              }
            }}
            focused
            color="grey"
            fullWidth
          />
          <TextField
            style={{ marginTop: '20px' }}
            label="Max"
            type="number"
            placeholder="₹"
            value={maxValue}
            onChange={handleMaxChange}
            InputProps={{
              style: { height: '40px', fontSize: '10px' },
              inputProps: {
                style: { fontSize: '12px', alignItems: 'center' }
              }
            }}
            color="grey"
            focused
            fullWidth
          />
        </div>

        <hr style={{ border: '1px solid', margin: '20px 0px 0px 0px', color: '#cec8c8' }} />
        <div style={{ fontSize: '20px', padding: '10px 24px 0px 24px' }}>Job Type</div>
        <hr style={{ border: '1px solid #edeaea', margin: '10px 0px 0px 0px', color: '#cec8c8' }} />

        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px 24px 0px 24px', gap: '10px' }}>
        <label style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <input
          type="checkbox"
          name="fullTime"
          checked={formState.fullTime}
          onChange={handleCheckboxChange}
        />
        <div>Full Time</div>
      </label>

      <label style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <input 
          type="checkbox"
          name="partTime"
          checked={formState.partTime}
          onChange={handleCheckboxChange}
        />
        <div>Part Time</div>
      </label>

      <label style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <input
          type="checkbox"
          name="onlineTeaching"
          checked={formState.onlineTeaching}
          onChange={handleCheckboxChange}
        />
        <div>Online Teaching</div>
      </label>
        </div>
      </div>

      <Button type="submit" variant="contained" color="primary" style={{ margin: '20px' }}>
        Apply Filter
      </Button>
    </form>

        </div>

      </div>
      <Footer />
    </>
  );
}

export default TeacherJobs;
