import logo from "../../assets/LogoHeader.svg";
import user from "../../assets/user.svg";
import img1 from "../../assets/img1.svg";
import img2 from "../../assets/img2.svg";
import doodle from "../../assets/doodle.png";
import posticon from "../../assets/posticon.svg";
import tutoricon from "../../assets/tutoricon.svg";
import { useMediaQuery } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import { Box, padding } from "@mui/system";
import FooterBack from "../../assets/FooterBack.svg";
import LogoFooter from "../../assets/LogoFooter.svg";
import location2 from "../../assets/location2.svg";
import Phone from "../../assets/Phone.svg";
import instagram from "../../assets/instagram.svg";
import twitter from "../../assets/twitter.svg";
import yt from "../../assets/youtube.png";
import linkedin from "../../assets/linkedin.png";
import Email from "../../assets/Email.svg";
import GooglePlay from "../../assets/GooglePlay.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import NotFound from "../pages/LandingPage/NotFound";
import WhatAppMobile from "../pages/LandingPage/WhatAppMobile";
import { Helmet } from "react-helmet";
import Footer from "../pages/LandingPage/Footer";
import NavBar from "../Navigation/NavBar";
import headerimg from "../../assets/Header-img.png";
import DottedArrow from "../../assets/DottedArrow.svg";
import line2 from "../../assets/line2.svg";
import Tutor2 from "../../assets/Tutor2.svg";
import Pin1 from "../../assets/Pin1.svg";
import Pin2 from "../../assets/Pin2.svg";
import Pin3 from "../../assets/Pin3.svg";
import Leaderstyle from "../../assets/Leaderstyle.svg";
import Leaderstyle2 from "../../assets/Leaderstyle2.svg";
import Leaderstyle3 from "../../assets/Leaderstyle3.svg";
import Leaderstyle4 from "../../assets/Best tutor.svg";
import Leaderstyle5 from "../../assets/Convenient.svg";
import Leaderstyle6 from "../../assets/Affordable.svg";
import Scale from "../../assets/Scale.svg";
import Frame from "../../assets/Frame.svg";
import Step1 from "../../assets/Step1.svg";
import Step2 from "../../assets/Step2.svg";
import Step3 from "../../assets/Step3.svg";
import line3 from "../../assets/line3.svg";
import Frame24 from "../../assets/Frame24.svg";
import Group221 from "../../assets/Group221.svg";
import Globe from "../../assets/Globe.svg";
import NoteBook from "../../assets/NoteBook.svg";
import class1_6 from "../../assets/1-6.jpg";
import class7 from "../../assets/7.jpg";
import class8 from "../../assets/8.jpg";
import class9 from "../../assets/9.jpeg";
import class10 from "../../assets/10.jpg";
import class11 from "../../assets/11.jpg";
import class12 from "../../assets/12.jpg";
import IIT from "../../assets/IIT.jpeg";
import Neet from "../../assets/Neet.jpeg";
import NDA from "../../assets/NDA.jpg";
import ArrowNew from "../../assets/ArrowNew.svg";
import Linenew from "../../assets/Linenew.svg";
import QuicklinkBg from "../../assets/QuicklinkBG.svg";
import PhoneDoodlePlus from "../../assets/PhoneDoodlePlus.svg";
import PhoneDoodleMultiply from "../../assets/PhoneDoodleMultiply.svg";
import PhoneDoodleBook from "../../assets/PhoneDoodleBook.svg";
import PhoneDoodleDivide from "../../assets/PhoneDoodleDivide.svg";
import GroupPhone from "../../assets/mobile-screen 2.svg";
import Frame25 from "../../assets/Frame25.svg";

const sxStyle = {
  display: "flex",
  justifyContent: { xs: "center" },
  alignItems: "center",
};

export const SitemapSlug = () => {
  const { slug } = useParams();
  const url = `https://app.kanhahometutions.com/api/v1/seo-pages?slug=${slug}`;
  const [title, setTitle] = useState(slug.replaceAll('-', ' '));
  const [error, setError] = useState(false); 
  const [description , setDescription] =useState()
  const [ seoscript , setSeoscript] = useState()
  const [loading, setLoading] = useState(true); 
  
  async function getSlug() {
    try {
      const response = await axios.get(url);
      if (response.data.slugs && response.data.slugs.length > 0) {
        const data = response.data.slugs[0];
        setTitle(data.txt_meta_title);
        setDescription(data.txt_meta_description)
        setSeoscript(data.txt_script);
      } else {
        setError(true); 
      }
    } catch (error) {
      console.error('Error fetching slugs:', error);
      setError(true); 
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    
    getSlug();
  }, []);



    const [isOpen, setIsOpen] = useState(false);
    const sidebarRef = useRef(null);
  
    const [selectedText, setSelectedText] = useState("");
    const [seoPages, setSeoPages] = useState([]);
  
    const toggleSidebar = (text, seoPages) => {
      setIsOpen((prev) => !prev);
      setSelectedText(text);
      setSeoPages(seoPages);
    };
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);
  
  const [quickLinks, setQuickLinks] = useState([]);

  async function getQuickLinks() {
    try {
      const response = await axios.get(
        `https://app.kanhahometutions.com/api/v1/quick-links`
      );

      setQuickLinks(response.data);
    } catch (error) {
      console.error("Error fetching:", error);
    }
  }

  useEffect(() => {
    getQuickLinks();
  }, []);

  const isXs = useMediaQuery("(min-width:600px) and (max-width:3000px)");

  const styles = {
    grid: {
      display: "grid",
      gridTemplateColumns: isXs ? "repeat(3, 1fr)" : "repeat(1, 1fr)",
      gap: "20px",
    },
    box: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px",
      backgroundColor: "#FFEBE2",
      border: "1px solid #ccc",
      borderRadius: "7px",
      boxShadow: "7px 7px 1px rgba(10, 10, 10, 0.17)",
      height: "3.7rem",
      width: "18rem",
      fontWeight: "500",
      cursor: "pointer",
      textAlign: "left",
    },
  };


  const handleEmailClick = () => {
    const mailtoLink = `mailto:kanhahometutions@gmail.com?subject=&body=`;
    window.open(mailtoLink, "_blank");
  };

  const khttwitter = () => {
    const playStoreUrl = "https://x.com/kanhahometution?s=21";
    window.open(playStoreUrl, "_blank");
  };

  const youtube = () => {
    const playStoreUrl = "https://www.youtube.com/@Kanhahome";
    window.open(playStoreUrl, "_blank");
  };

  const khtlinkedin = () => {
    const playStoreUrl =
      "https://www.linkedin.com/company/kanha-home-tuitions/posts/?feedView=all";
    window.open(playStoreUrl, "_blank");
  };

  const khtinstagram = () => {
    const playStoreUrl =
      "https://www.instagram.com/kanha_home_tuition?igsh=c29rOWFyY29wdm5m";
    window.open(playStoreUrl, "_blank");
  };

  const handleClickfortutor = () => {
    const playStoreUrl = "https://play.google.com/store/apps/details?id=com.kanhahometutions.tutor";
    window.open(playStoreUrl, "_blank");
  };

  const handleClickforstudent = () => {
    const playStoreUrl = "https://play.google.com/store/apps/details?id=com.kanhahometutions.student";
    window.open(playStoreUrl, "_blank");
  };

  
  const openSocialMediaLink = (link) => {
    window.open(link, "_blank");
  };

  if (loading) {
    return <div>Loading...</div>; 
  }

  if (error) {
    return (
      <>
      <NotFound/>
      
      </>
    );
  }


  return (
    <>
    <Helmet>
      <title>{title}</title>
      <meta name="meta_description" content={description}></meta>
    </Helmet>
    
                          <WhatAppMobile/>
                          <NavBar/>


                          <div
  style={{
    width: "100%",
    // position: "relative", // Keep it relative to position content inside
    backgroundColor: isXs ? "" : "#CB8B65",
    height: isXs ? "d" : "50rem",
  }}
>
  {/* Background Image */}
  <img
    style={{
      width: "100%",
      display: isXs ? "" : "none",
      position: "relative", // This will make the image cover the entire container
      top: 0,
      left: 0,
      objectFit: 'cover', // Ensure the image covers the full area
      height: "100%",
    }}
    src={headerimg}
    alt=""
  />

  {/* Content Over the Image */}
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      flexDirection: isXs ? "" : "column",
      position: "absolute",
      top: "60%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      zIndex: 10,
      padding:'0px 5%'
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: isXs ? "1.5rem" : "2rem",
        width: isXs ? "50%" : "",
        justifyContent: isXs ? "" : "center",
        alignItems: isXs ? "" : "center",
      }}
    >
      <h3
        className="text-white"
        style={{
          fontSize: isXs ? "1.5vw" : "4.8vw",
          display: isXs ? "" : "",
        }}
      >
        Welcome to Kanha Home Tutions
      </h3>

      <h1
        className="text-white fw-bold"
        style={{
          fontSize: isXs ? "3vw" : "6.6vw",
          lineHeight: isXs ? "3rem" : "1.8rem",
          textAlign: isXs ? "" : "center",
        }}
      >
        Best Tutor for <br /> Home and Online Tutions
      </h1>

      <img src={line2} style={{ width: "25%", maxWidth: "200px" }} />
      <div
        className="text-white"
        style={{
          width: isXs ? "100%" : "70%",
          fontSize: isXs ? "1vw" : "3.5vw",
          textAlign: isXs ? "" : "center",
        }}
      >
        <span>
          {" "}
          Connect with expert tutors for online or home tuitions, tailored
          to your learning needs and personal style. We offer classes for
          IIT-JEE, NEET, NDA, 12th, 11th, and all subjects across all
          grades, available all over India.
        </span>
      </div>

      <a href="/student-signin">
          <button
            style={{
              padding: ".8rem 0.5rem",
              border: "none",
              boxShadow: "4px 8px 4px rgb(0, 0, 0, .1)",
              borderRadius: "5px",
              fontSize: isXs ?  "1.4rem":'1rem',
              marginTop: isXs? "0.5rem":"2rem" ,
              fontWeight: "bold",
              backgroundColor: "#FFDB7E",
              color: "#866D2C",
              gap: "40rem",
              width: isXs? '35rem':'22.4rem',
            }}
            type="button"
          >
Post Your Home Tuition or Tutor Requirements
          </button>
        </a>
    </div>

    {/* Tutor Image */}
    <div
      style={{
        display: "flex",
        justifyContent: isXs ? "end" : "center",
        alignItems: "center",
        marginTop: isXs ? "" : "2rem",
      }}
    >
      <img style={{ width: "90%" }} src={Tutor2} />
    </div>
  </div>
</div>

      <div
        style={{
          backgroundColor: "#F6EBDC",
          width: "100%",
          height: isXs ? "44.5rem" :'58rem',
          padding: "1.5rem 3rem",
          display: "flex",
          flexDirection: "column",
          gap: isXs ? "4rem" : "2.7rem",
          position: "absolute",
          backgroundImage: `url(${doodle})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: isXs ?  "0px 200px":'center',
          zIndex:'-2',
          top: isXs ? '430px':'480px',
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "jost",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            gap: "1rem",
            marginTop:isXs ?  '250px':'0px'
          }}
        >
          <h1
            style={{
              textAlign: "center",
              marginTop:isXs?'':'30rem',
              fontSize: isXs ? "3rem" : "1.8rem",
              marginBottom: '1rem',
              color:'#CB8B65'
            }}
          >
            {title}
          </h1>

<div style={{position: isXs ? 'absolute':'', display:isXs ? 'flex':'flex', gap:'10px', flexDirection:'column', bottom: isXs ? '230px':'100px', justifyContent: isXs ? 'center':''}}>
          <div
            style={{
              display:isXs ? "flex" :'none',
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              fontSize: isXs ? "" : ".5rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".2rem",
              }}
            >
              <img width={15} style={{height: isXs ? '':'16px', fill: 'black'}} src={user} alt="" />
              <span style={{fontSize:isXs ? '':'10px', color:'black'}}>Sign Up Easily</span>
            </div>
            <div style={{color:'black'}}>|</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".2rem",
              }}
            >
              <img width={15} style={{height: isXs ? '':'16px'}}  src={posticon} alt="" />
              <span style={{fontSize:isXs ? '':'10px', color:'black'}}>Post Tutoring Requirements</span>
            </div>
            <div style={{color:'black'}}>|</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".2rem",
              }}
            >
              <img width={15} style={{height: isXs ? '':'16px'}}  src={tutoricon} alt="" />
              <span style={{fontSize:isXs ? '':'10px', color:'black'}} >Choose the Ideal Tutor</span>
            </div>
          </div>

          <div
            style={{textAlign: "center", marginTop: isXs? "1rem":'1rem', fontSize:isXs ? '':'19px', display:'flex', justifyContent:'center' }}
          >
            <div style={{color:'black'}}>
            Connect with expert tutors tailored to fit your academic needs and
            personal learning style.
            </div>
          </div>
          </div>

        </div>
        <div
          style={{
            display: isXs ? "flex" :'flex',
            justifyContent: isXs ? "space-between":'start',
            alignItems: "center",
            position: "absolute",
            gap: isXs ? "40rem" :'0rem',
            top: isXs?"30.7rem":'30.2rem',
            left:isXs ? '':'34px'
          }}
        >
          <img style={{ width: isXs ? "19%" : "100%" , display:isXs ? 'flex' :'none'}} src={img1} alt="" />
          <img style={{ width: isXs ? "22%" : "30%",marginTop:'10px', display:isXs ? 'flex' :'none'}}  src={img2} alt="" />
        </div>

      </div>


      <Grid
        container
        sx={{
          backgroundColor: "#CB8B65",
          display: "flex",
          marginTop: isXs ? "0%" : "0",
           marginTop:'500px'
        }}
      >
        <div
          className="py-4 d-flex justify-content-center"
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "28px",
            width: "100%",
            marginTop: isXs ? "3%" : "12%",
            margin: "0 auto",
          }}
        >
          Why Choose Our Tutors?
        </div>

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: isXs ? "space-evenly" : "center",
            alignItems: isXs ? "" : "center",
            marginTop: isXs ? "1%" : "20%",
          }}
        >
          <Grid
            item
            xs={10}
            sm={10}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            sx={{ position: "relative" }}
          >
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Leaderstyle}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-20%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
                Proven Expertise:
              </div>
              <div>
                Each tutor is rigorously selected based on their knowledge,
                teaching experience, and ability to adapt to different learning
                styles and needs.
              </div>
              <div style={{ marginBottom: "15%" }}>
                <img
                  src={Pin1}
                  style={{
                    position: "absolute",
                    bottom: "-10%",
                    left: "8%",
                  }}
                />
              </div>
            </div>
            
            <div className="text-end" style={{ marginTop: "30%" }}>
              <img src={Scale} style={{ display: isXs ? "none" : "none" }} />
            </div>
            <img
              src={Scale}
              style={{
                position: "absolute",
                zIndex: 2,
                left: "-3rem",
                bottom: "1rem",
                width: "30%",
                display: isXs ? "none" : "",
              }}
            />
          </Grid>

          <Grid
            item
            xs={10}
            sm={10}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            sx={{ marginTop: "5%" }}
          >
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Pin2}
                  style={{
                    position: "absolute",
                    top: "-12%",
                    left: "8%",
                  }}
                />
              </div>
              <div>
                <img
                  src={Leaderstyle2}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-18%",
                    right: "36%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
                Customized Approach:
              </div>
              <div style={{ marginBottom: "15%" }}>
                Our tutors understand that each student is unique. They
                customize their teaching methods to match individual learning
                paces and preferences, ensuring optimal comprehension and
                retention.
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={10}
            sm={10}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            sx={{
              position: "relative",
              marginTop: isXs ? "" : "30%",
              marginBottom: isXs ? "" : "20%",
            }}
          >
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Leaderstyle3}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-20%",
                    right: "8%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
                Results-Oriented:
              </div>
              <div>
                Focused on achieving tangible academic improvements, our tutors
                are committed to helping each student reach and surpass their
                academic goals.
              </div>
              <div style={{ marginBottom: "15%" }}>
                <img
                  src={Pin3}
                  style={{
                    position: "absolute",
                    bottom: "-9%",
                    right: "8%",
                  }}
                />
              </div>
            </div>
            <div
              className="col-lg-12 d-flex justify-content-end"
              style={{ backgroundColor: "#CB8B65" }}
            >
              {/* <img
                src={Frame}
                style={{
                  width: "40%",
                  position: "absolute",
                  right: "-25%",
                  marginTop: "30%",
                  display: isXs ? "" : "none",
                }}
              /> */}
              {/* <img
                src={Frame}
                style={{
                  position: "absolute",
                  zIndex: 2,
                  right: "-2rem",
                  width: "30%",
                  display: isXs ? "none" : "",
                }}
              /> */}
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: isXs ? "space-evenly" : "center",
            alignItems: isXs ? "" : "center",
            marginTop: isXs ? "1%" : "10%",
          }}
        >
          <Grid
            item
            xs={10}
            sm={10}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            sx={{ position: "relative" }}
          >
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Leaderstyle4}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-20%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
              Best Home Tutors:
              </div>
              <div>
              We provide the best home tutors in India, ensuring that students receive the highest quality education. 
              </div>
              <div style={{ marginBottom: "15%" }}>
                <img
                  src={Pin1}
                  style={{
                    position: "absolute",
                    bottom: "-10%",
                    left: "8%",
                  }}
                />
              </div>
            </div>
            
            <div className="text-end" style={{ marginTop: "30%" }}>
              <img src={Scale} style={{ display: isXs ? "" : "none" }} />
            </div>
            <img
              src={Scale}
              style={{
                position: "absolute",
                zIndex: 2,
                left: "-3rem",
                bottom: "1rem",
                width: "30%",
                display: isXs ? "none" : "",
              }}
            />
          </Grid>

          <Grid
            item
            xs={10}
            sm={10}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            sx={{ marginTop: "5%" }}
          >
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Pin2}
                  style={{
                    position: "absolute",
                    top: "-12%",
                    left: "8%",
                  }}
                />
              </div>
              <div>
                <img
                  src={Leaderstyle5}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-18%",
                    right: "36%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
              Expert Coaching:
              </div>
              <div style={{ marginBottom: "15%" }}>
              IIT-JEE, NEET & BITSAT Coaching: Our teachers have extensive experience in coaching students for IIT-JEE, NEET & BITSAT with a deep understanding of the exam pattern and syllabus.
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={10}
            sm={10}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            sx={{
              position: "relative",
              marginTop: isXs ? "" : "30%",
              marginBottom: isXs ? "" : "20%",
            }}
          >
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Leaderstyle6}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-20%",
                    right: "8%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
              Excellence:
              </div>
              <div>
              11th and 12th Class Tuitions: Our teachers are experts in their respective subjects and have a proven track record of helping students achieve high grades in their 11th and 12th class ( CBSE, ICSE,IB,& State Boards) exams.
              </div>
              <div style={{ marginBottom: "15%" }}>
                <img
                  src={Pin3}
                  style={{
                    position: "absolute",
                    bottom: "-9%",
                    right: "8%",
                  }}
                />
              </div>
            </div>
            <div
              className="col-lg-12 d-flex justify-content-end"
              style={{ backgroundColor: "#CB8B65" }}
            >
              <img
                src={Frame}
                style={{
                  width: "40%",
                  position: "absolute",
                  right: "-25%",
                  marginTop: "30%",
                  display: isXs ? "" : "none",
                }}
              />
              <img
                src={Frame}
                style={{
                  position: "absolute",
                  zIndex: 2,
                  right: "-2rem",
                  width: "30%",
                  display: isXs ? "none" : "",
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={sxStyle}>
        <div
          className="text-center"
          style={{
            marginTop: isXs ? "5%" : "10%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: isXs ? "1.6vw" : "4.5vw",
              fontWeight: "bold",
              width: isXs ? "60%" : "70%",
            }}
          >
            How Our Kanha Home Tutions Service Simplifies Your Search for the
            Perfect Tutor
          </div>
          <img
            src={line3}
            style={{
              width: "30%",
              maxWidth: "200px",
              margin: "auto",
              marginTop: isXs ? "1rem" : "2rem",
            }}
          />
        </div>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          sx={sxStyle}
        >
          <div
            style={{
              marginTop: isXs ? "5%" : "10%",
              backgroundColor: "#F6EBDC",
              width: isXs ? "65%" : "80%",
              height: isXs ? "17.2rem" : "40rem",
              position: "relative",
              borderTopLeftRadius: isXs ? "500px" : "200px",
              borderBottomLeftRadius: isXs ? "500px" : "",
              borderBottomRightRadius: isXs ? "500px" : "",
              borderTopRightRadius: isXs ? "" : "200px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={10}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                sx={{ margin: isXs ? "" : "0 auto" }}
              >
                <img
                  src={Step1}
                  alt="Step1"
                  style={{
                    width: isXs ? "94%" : "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%",
                    marginTop: isXs ? "" : "11%",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ marginRight: isXs ? "8%" : "0 auto" }}>
                  <div
                    className="d-flex fw-bold"
                    style={{
                      fontSize: isXs ? "2vw" : "5vw",
                      justifyContent: isXs ? "" : "center",
                    }}
                  >
                    <Grid
                      container
                      justifyContent={isXs ? "flex-start" : "center"}
                      sx={{ gap: isXs ? "0.5rem" : "" }}
                    >
                      <Grid item xs={10} sm={2} md={2} lg={2} xl={2} xxl={2}>
                        <div
                          style={{
                            color: "#CB8B65",
                            textAlign: isXs ? "" : "center",
                            marginTop: isXs ? "" : "11%",
                          }}
                        >
                          Step 1
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={5}
                        md={5}
                        lg={5}
                        xl={5}
                        xxl={5}
                        sx={{ right: "5%" }}
                      >
                        <div style={{ textAlign: isXs ? "" : "center" }}>
                          Sign Up Easily
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      fontSize: isXs ? "1vw" : "4vw",
                      textAlign: isXs ? "" : "center",
                      width: isXs ? "" : "80%",
                      margin: isXs ? "" : "0 auto",
                      marginTop: isXs ? "2%" : "8%",
                    }}
                  >
                    Start by registering on the Kanha Home Tuitions website or
                    app, or simply contact us directly. This step allows you to
                    clarify your specific tutoring needs and preferences,
                    ensuring we connect you with the ideal candidates.
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          sx={sxStyle}
          style={{ display: isXs ? "" : "none" }}
        >
          <div
            className="d-flex align-items-center py-3"
            style={{
              marginTop: "5%",
              backgroundColor: "#CB8B65",
              width: isXs ? "65%" : "80%",
              height: isXs ? "17.2rem" : "30rem",
              position: "relative",
              borderTopLeftRadius: isXs ? "" : "200px",
              borderBottomLeftRadius: isXs ? "500px" : "",
              borderBottomRightRadius: isXs ? "500px" : "",
              borderTopRightRadius: isXs ? "500px" : "200px",
            }}
          >
            <Grid
              container
              sx={{ display: "flex", justifyContent: isXs ? "flex-end" : "" }}
            >
              <Grid
                item
                xs={10}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                xxl={7}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div>
                  <div
                    className="d-flex fw-bold"
                    style={{ fontSize: "2vw", gap: "1rem" }}
                  >
                    <div style={{ color: "#FFDB7E", width: "100px" }}>
                      Step 2
                    </div>
                    <div style={{ color: "white", width: "80%" }}>
                      Post Tutoring Requirements
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "1vw",
                      color: "white",
                      width: "90%",
                      marginTop: isXs ? "2%" : "",
                    }}
                  >
                    Create a detailed post outlining your child’s academic
                    needs, preferred schedule, and any special considerations.
                    You can also browse posts from our extensive network of
                    qualified tutors to find the right match.
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={10}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <img
                  src={Step2}
                  alt="Step2"
                  style={{
                    width: isXs ? "95%" : "120%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%",
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          sx={sxStyle}
          style={{ display: isXs ? "none" : "", marginTop: "5%" }}
        >
          <div
            style={{
              marginTop: "5%",
              backgroundColor: "#CB8B65",
              width: isXs ? "65%" : "80%",
              height: isXs ? "17.2rem" : "40rem",
              position: "relative",
              borderTopLeftRadius: isXs ? "500px" : "200px",
              borderBottomLeftRadius: isXs ? "500px" : "",
              borderBottomRightRadius: isXs ? "500px" : "",
              borderTopRightRadius: isXs ? "" : "200px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={10}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                xxl={5}
                sx={{ margin: isXs ? "" : "0 auto" }}
              >
                <img
                  src={Step2}
                  alt="Step2"
                  style={{
                    width: isXs ? "93%" : "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%",
                    marginTop: isXs ? "" : "11%",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={7}
                lg={7}
                xl={7}
                xxl={7}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ margin: isXs ? "" : "0 auto" }}>
                  <div
                    className="d-flex fw-bold"
                    style={{
                      fontSize: isXs ? "2vw" : "5vw",
                      justifyContent: isXs ? "" : "center",
                    }}
                  >
                    <Grid
                      container
                      justifyContent={isXs ? "flex-start" : "center"}
                    >
                      <Grid
                        item
                        xs={10}
                        sm={2.5}
                        md={2.5}
                        lg={2.5}
                        xl={2.5}
                        xxl={2.5}
                      >
                        <div
                          style={{
                            color: "#FFDB7E",
                            marginRight: "0.5rem",
                            textAlign: isXs ? "" : "center",
                            marginTop: isXs ? "" : "11%",
                          }}
                        >
                          Step 2
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={5}
                        md={5}
                        lg={5}
                        xl={5}
                        xxl={5}
                        sx={{ right: "5%" }}
                      >
                        <div
                          style={{
                            textAlign: isXs ? "" : "center",
                            color: "white",
                          }}
                        >
                          Post Tutoring Requirements
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      fontSize: isXs ? "1vw" : "4vw",
                      color: "white",
                      textAlign: isXs ? "" : "center",
                      width: isXs ? "" : "80%",
                      margin: isXs ? "" : "0 auto",
                      marginTop: isXs ? "" : "8%",
                    }}
                  >
                    Create a detailed post outlining your child’s academic
                    needs, preferred schedule, and any special considerations.
                    You can also browse posts from our extensive network of
                    qualified tutors to find the right match.
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          sx={sxStyle}
          style={{ marginTop: "5%" }}
        >
          <div
            style={{
              marginTop: "0.2%",
              backgroundColor: "#F6EBDC",
              width: isXs ? "65%" : "80%",
              height: isXs ? "17.2rem" : "42rem",
              position: "relative",
              borderTopLeftRadius: isXs ? "500px" : "200px",
              borderBottomLeftRadius: isXs ? "500px" : "",
              borderBottomRightRadius: isXs ? "500px" : "",
              borderTopRightRadius: isXs ? "" : "200px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={10}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                sx={{ margin: isXs ? "" : "0 auto" }}
              >
                <img
                  src={Step3}
                  alt="Step3"
                  style={{
                    width: isXs ? "94%" : "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%",
                    marginTop: isXs ? "" : "11%",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ margin: isXs ? "" : "0 auto" }}>
                  <div
                    className="d-flex fw-bold"
                    style={{
                      fontSize: isXs ? "2vw" : "5vw",
                      justifyContent: isXs ? "" : "center",
                    }}
                  >
                    <Grid
                      container
                      justifyContent={isXs ? "flex-start" : "center"}
                      sx={{ gap: isXs ? "0.5rem" : "" }}
                    >
                      <Grid item xs={10} sm={2} md={2} lg={2} xl={2} xxl={2}>
                        <div
                          style={{
                            color: "#CB8B65",
                            textAlign: isXs ? "" : "center",
                            marginTop: isXs ? "" : "11%",
                          }}
                        >
                          Step 3
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        sx={{ right: "5%" }}
                      >
                        <div style={{ textAlign: isXs ? "" : "center" }}>
                          Choose the Ideal Tutor
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      fontSize: isXs ? "1vw" : "4vw",
                      textAlign: isXs ? "" : "center",
                      width: isXs ? "95%" : "80%",
                      margin: isXs ? "" : "0 auto",
                      marginTop: isXs ? "2%" : "8%",
                    }}
                  >
                    After posting your requirements, you'll receive responses
                    from our vetted tutors. Arrange a demo class to assess the
                    tutor’s compatibility with your child’s learning style and
                    needs. Once satisfied, you can hire the tutor and discuss
                    any further details directly.
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {/* Section6 */}
      <div style={{ marginTop: "8rem" }}>
        <img
          src={Frame24}
          alt="Frame24"
          style={{
            position: "absolute",
            width: "100%",
            display: isXs ? "" : "none",
            height: "auto",
          }}
        />
        <img
          src={Group221}
          alt="Group221"
          style={{
            position: "absolute",
            paddingTop: "4.2rem",
            left: "3.3rem",
            width: isXs ? "96%" : "0%",
          }}
        />
      </div>

      <div
        className="container-fluid"
        style={{
          position: "relative",
          overflow: "hidden",
          zIndex: 3,
          backgroundColor: isXs ? "" : "#CB8B65",
          padding: isXs ? "" : "3rem 0rem",
          height: isXs ? "auto" : "570px",
        }}
      >
        <div
          className="text-center"
          style={{
            marginTop: isXs ? "6%" : "1%",
            height: isXs ? "20rem" : "",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: isXs ? "2.2vw" : "6.6vw",
              fontWeight: "bold",
              width: isXs ? "55%" : "90%",
              color: "white",
            }}
          >
            Kickstart Your Child’s Academic Success Today!
          </div>
          <div
            style={{
              fontSize: isXs ? "1.2vw" : "4vw",
              width: isXs ? "70%" : "90%",
              color: "white",
              marginTop: isXs ? "3%" : "8%",
              textAlign: isXs ? "" : "center",
            }}
          >
            Forget the hassle of endless searching for 'tuition near me'. With
            Kanha Home Tutions, discover a personalized, efficient, and reliable
            approach to finding the perfect tutor for your child. Join our
            community of satisfied parents and witness your child's educational
            transformation.
          </div>
          <div
            className="d-flex justify-content-evenly"
            style={{
              width: isXs ? "60%" : "85%",

              marginTop: isXs ? "4%" : "10%",
              fontSize: "1.4vw",
            }}
          >
            
            <button 
              className="py-2"
              type="button"
              // onClick={handleOpen}
              style={{
                backgroundColor: "#FFDB7E",
                color: "#866D2C",
                border: "none",
                borderRadius: "3px",
                width: isXs ? "70%" : "20rem",
                height: isXs ? "4rem" : "5rem",
                fontWeight: "bold",
                fontSize: isXs ? "1.5rem" : "1.6rem",
                boxShadow: "5px  5px 2px rgb(0,0,0, .2)",
              }}
            >
              <a style={{textDecoration:'none' ,color:'black'}} href="/student-signin">
              Post Your Requirments
              </a>
            </button>
           
           
          </div>
          <img
            src={Globe}
            style={{
              position: "absolute",
              zIndex: 2,
              right: "-1rem",
              top: "1rem",
              width: "12%",
              display: isXs ? "none" : "",
            }}
          />
        </div>

        <img
          src={NoteBook}
          style={{
            position: "absolute",
            zIndex: 2,
            left: "3rem",
            bottom: "-1rem",
            width: "15%",
            display: isXs ? "none" : "",
          }}
        />
      </div>

      <div
        style={{
          height: isXs ? "800px" : "1150px",
          backgroundColor: "#F6EBDC",
          width: "100%",
          marginTop: isXs ? "2rem" : "0rem",
          marginBottom: isXs ? "0rem" : "7rem",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              fontSize: "28px",
              paddingTop: isXs ? "120px" : "50px",
              fontWeight: "bold",
            }}
          >
            Explore Categories
          </div>
        </div>
        <div
          style={{
            fontSize: "1.2rem",
            fontWeight: "bolder",
            marginTop: isXs ? "10px" : "20px",
            textAlign: isXs ? "center" : "center",
          }}
        >
          Tutors for home and online Tution
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isXs ? "repeat(5, 250px)" : "repeat(2, 1fr)",
            justifyItems: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: isXs ? "2rem 1rem" : "2rem 1rem",
            gap: isXs ? "" : "0.7rem",
            rowGap: isXs ? "50px" : "2rem",
          }}
        >
          {[
            { img: class1_6, label: "Class 1-6 Tution" },
            { img: class7, label: "Class 7 Tution" },
            { img: class8, label: "Class 8 Tution" },
            { img: class9, label: "Class 9 Tution" },
            { img: class10, label: "Class 10 Tution" },
            { img: class11, label: "Class 11 Tution" },
            { img: class12, label: "Class 12 Tution" },
            { img: IIT, label: "IIT-JEE Tution" },
            { img: Neet, label: "NEET Tution" },
            { img: NDA, label: "NDA Tution" },
          ].map((item, index) => (
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/student-signin"
              key={index}
            >
              <div
                key={index}
                style={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <div>
                  <img
                    src={item.img}
                    alt=""
                    style={{
                      objectFit: "cover",
                      borderRadius: "7px",
                      height: isXs ? "150px" : "120px",
                      width: "100%",
                      maxWidth: "220px",
                    }}
                  />
                </div>
                <div>{item.label}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
  
      <div style={{padding: isXs ? '40px 70px':'40px 30px', display:'flex', flexDirection:'column', gap:'12px', marginBottom: isXs ? '80px':'10px',}}>
   

<div style={{display:'flex', justifyContent:'center'}}>
      <span style={{width : isXs ?"1000px":'100%', display:'flex', flexDirection:'column', backgroundColor:'#F8E7C9',padding: isXs ? '20px 35px':'10px 10px', color:'black', borderRadius:'20px', marginTop:'0px' }}>
          <span style={{fontSize: '26px', fontWeight:'500px', textAlign:'center'}}>Best Tutor for Home and Online Tution</span>
          <hr style={{marginTop: '5px',marginBottom: '5px',border: '1px',borderTop: '2px solid rgb(137, 57, 11)', width: isXs ? '76%':'100%', marginLeft: isXs ?'70px':'0px', color: 'rgb(137, 57, 11)' }} />
            <span style={{fontSize:'16px', fontWeight:'500px', textAlign:'center',marginTop:'10px', width: isXs ? '100%':'100%'}}>Connect with expert tutors for online or home tuitions, tailored to your learning needs and personal style. We offer classes for IIT-JEE, NEET, NDA, 12th, 11th, and all subjects across all grades, available all over India.</span>
     </span>
     </div>
            
      <div style={{textAlign:'center', fontSize:'28px', fontWeight:'bold', marginBottom: isXs ?'40px':'20px', marginTop: isXs ? '40px':'20px'}}>Why Choose Kanha Home Tuition?</div>
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>Home Tution:</span> </div>
      <div style={{fontSize:'16px'}}>Experienced and qualified home tutors for all subjects and classes, including IIT JEE, NEET, 12th, 11th, and up to 10th. Our home tutors provide personalized attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects to help students achieve their academic goals.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>Best Home Tutors:</span> </div>
      <div style={{fontSize:'16px'}}>Our team of expert home tutors are highly qualified and experienced in their respective subjects. </div>
      <ul style={{display:'flex', flexDirection:'column', gap:'5px', paddingLeft:'0px'}}>
      <li style={{fontSize:'16px',listStyle:"none"}}><span style={{fontSize:'16px', fontWeight:'500'}}>1. Personalized Attention:</span> We provide one-on-one attention to each student, ensuring they receive the best possible guidance and support. </li>
      <li style={{fontSize:'16px',listStyle:"none"}}><span style={{fontSize:'16px', fontWeight:'500'}}>2. Flexible Scheduling:</span> We understand that every student's schedule is different, which is why we offer flexible scheduling to fit your needs. </li>
      <li style={{fontSize:'16px',listStyle:"none"}}><span style={{fontSize:'16px', fontWeight:'500'}}>3. Proven Track Records:</span> Our home tutors have a proven track record of success, with many students achieving top grades and securing admission to top universities.</li>
      </ul>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>Online Tution ( One to One ):</span> </div>
      <div style={{fontSize:'16px'}}>Flexible and convenient online tuition for students of all ages and subjects, including IIT JEE, NEET, 12th, 11th, and up to 10th. Our online tutors provide one-on-one attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects to help students learn at their own pace. </div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>Online Classes:</span> </div>
      <div style={{fontSize:'16px'}}>IIT- JEE, NEET, NDA (One to One) Attention  </div>
      <div style={{fontSize:'16px'}}>Our online classes provide specialized coaching for, IIT- JEE, NEET, NDA. with a focus on personalized attention and guidance for each student. Our experienced and qualified tutors offer online coaching, IIT- JEE, NEET, NDA ensuring that students receive individualized support and guidance to achieve their academic goals. </div>
      <ul style={{display:'flex', flexDirection:'column', gap:'5px',}}>
        <li style={{fontSize:'16px'}}>( One to One ) online coaching for IIT- JEE,NEET,NDA </li>
        <li style={{fontSize:'16px'}}>Experienced and qualified tutors</li>
        <li style={{fontSize:'16px'}}>Personalized attention and guidance</li>
        <li style={{fontSize:'16px'}}>Flexible and convenient online classes</li>
        <li style={{fontSize:'16px'}}>Comprehensive study material and resources</li>
      </ul>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      
      
      
      
      <div style={{textAlign:'center', fontSize:'28px', fontWeight:'bold',marginBottom: isXs ?'40px':'20px', marginTop: isXs ? '40px':'20px'}}>Subjects and Levels We Cover</div>
      <div style={{display:'flex', gap:'3px', flexDirection:'column'}}>
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>Maths Tutors:</span></div>
      <div style={{fontSize:'16px'}}> Our experienced maths tutors provide expert guidance and support to help student’s master mathematical concepts. </div>
      <hr style={{  marginTop: '10px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      <div></div>
      </div>
      <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>Physics Tutors:</span></div>
      <div style={{fontSize:'16px'}}> Our physics tutors are highly qualified and experienced in helping students understand complex physics concepts.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      </div>
      <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>Chemistry Tutors:</span></div>
      <div style={{fontSize:'16px'}}> Our chemistry tutors provide expert guidance and support to help students understand chemical reactions and processes.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      </div>
      <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>Biology Tutors:</span> </div>
      <div style={{fontSize:'16px'}}> Our biology tutors are highly qualified and experienced in helping students understand biological concepts and processes. </div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      </div>
      <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>IIT JEE Tutors (Mains and Advanced): </span> </div>
      <div style={{fontSize:'16px'}}> Our experienced IIT JEE tutors provide expert guidance and support to help students prepare for IIT JEE exams. </div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      </div>
      <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>NEET Tutors:</span> </div>
      <div style={{fontSize:'16px'}}>Our NEET tutors are highly qualified and experienced in helping students prepare for NEET exams. </div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      </div>
      <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>NDA Tutors:</span> </div>
      <div style={{fontSize:'16px'}}>Our NDA tutors provide expert guidance and support to help students prepare for NDA exams.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      </div>
      <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>Commerce Tutors</span> </div>
      <div style={{fontSize:'16px'}}> Our commerce tutors cover all subjects, including Accountancy, Business Studies, Economics, and more.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      </div>
      <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>Arts Tutors:</span> </div>
      <div style={{fontSize:'16px'}}>Our arts tutors cover all subjects, including History, Geography, Political Science, and more. </div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      </div>
      <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>12th Tutors:</span> </div>
      <div style={{fontSize:'16px'}}>Our 12th tutors cover all subjects, including math, science, commerce, and arts.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      </div>
      <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}> 11th Tutors:</span> </div>
      <div style={{fontSize:'16px'}}>Our 11th tutors cover all subjects, including math, science, commerce, and arts. </div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      </div>
      <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>CBSE Tutors:</span> </div>
      <div style={{fontSize:'16px'}}>Our CBSE tutors are highly qualified and experienced in helping students prepare for CBSE exams. </div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      </div>
      
      <div style={{display:'flex', flexDirection:'column', gap:'3px'}}>
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>Online Tutions and Tutors:</span> </div>
      <div style={{display:'flex', flexDirection:'column', gap:'5px'}}>
      <div style={{fontSize:'16px'}}>1. We also provide online tutions and tutors for all subjects and levels, ensuring that students can learn from the comfort of their own homes. </div>
      <div style={{fontSize:'16px'}}>2. Our online tutors are highly qualified and experienced, and use the latest technology to provide interactive and engaging lessons.</div>
      </div>
      <hr style={{  marginTop: '10px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      </div>
      
      <div style={{display:'flex', flexDirection:'column', gap:'3px'}}>
      <div style={{fontSize:'16px'}}> <span style={{fontSize:'18px', fontWeight:'500'}}>Services We Provide:</span> </div>
      <div style={{display:'flex', flexDirection:'column', gap:'5px'}}>
      <div style={{fontSize:'16px'}}><span style={{fontSize:'16px', fontWeight:'500'}}>1. Home Tutions:</span> We provide home tutions for all subjects and levels, ensuring that students receive personalized attention and guidance.</div>
      <div style={{fontSize:'16px'}}><span style={{fontSize:'16px', fontWeight:'500'}}>2. Online Tutions:</span> We provide online tutions for all subjects and levels, ensuring that students can learn from the comfort of their own homes. </div>
      <div style={{fontSize:'16px'}}><span style={{fontSize:'16px', fontWeight:'500'}}>3. Group Tutions:</span> We provide group tutions for students who prefer to learn in a group environment.</div>
      <div style={{fontSize:'16px'}}><span style={{fontSize:'16px', fontWeight:'500'}}>4. Test Preparation:</span> We provide test preparation services for students who are preparing for competitive exams.</div>
      
      </div>
      <hr style={{  marginTop: '10px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      </div>
      </div>


      <div style={{ marginTop: "80px ", marginBottom: isXs ? "" : "100px" }}>
        <div style={{ position: "relative" }}>
          <div
            style={{
              textAlign: "center",
              backgroundColor: isXs ? "" : "#C9875F",
              height: isXs ? "" : "730px",
              borderBottomLeftRadius: "50px",
              borderTopRightRadius: "50px",
              display: "flex",
              justifyContent: "center",
              margin: isXs ? "" : "0px 5%",
            }}
          >
            <img
              style={{ width: isXs ? "80%" : "0%" }}
              src={QuicklinkBg}
              alt="QuicklinkBg"
            />
            <div
              style={{
                position: "absolute",
                top: "32px",
                fontSize: "28px",
                fontWeight: "bold",
                left: isXs ? "44.5%" : "",
                color: "white",
                display: "flex",
                flexDirection: "column",
                textAlign: isXs ? "" : "center",
              }}
            >
              Quick Links{" "}
              <img style={{ width: "70px" }} src={Linenew} alt="LineNEW" />
            </div>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div style={styles.grid}>
                {quickLinks.slice(0, 6).map((boxText, index) => (
                  <div
                    onClick={() =>
                      toggleSidebar(boxText.quick_link_name, boxText.seo_pages)
                    }
                    key={index}
                    style={styles.box}
                  >
                    {boxText.quick_link_name}
                    <img
                      src={ArrowNew}
                      style={{ height: "30px", marginLeft: "10px" }}
                      alt=""
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Sidebar */}
        <div style={{ position: "absolute", zIndex: "1000" }}>
          <div
            ref={sidebarRef}
            style={{
              position: "fixed",
              top: 40,
              right: 0,
              height: isXs ? "530px" : "750px",
              width: isXs ? "390px" : "340px",
              backgroundColor: "#efe8e8",
              color: "black",
              transform: isOpen ? "translateX(00)" : "translateX(100%)",
              transition: "transform 0.3s",
              boxShadow: "1px 2px 2px rgb(10, 10, 10 ,0.2)",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "0px",
                backgroundColor: "#A6653F",
                color: "white",
                padding: "10px 10px",
              }}
            >
              <div
                style={{
                  fontWeight: "500",
                  fontSize: "18px",
                  paddingRight: "16px",
                  top: "0",
                  position: "sticky",
                  zIndex: "1000",
                }}
              >
                {/* Add this button text */}
                {selectedText}
              </div>
              {isOpen && (
                <div
                  onClick={() => toggleSidebar()}
                  style={{ cursor: "pointer", color: "white" }}
                >
                  {/* <CloseIcon style={{ backgroundColor: '#f7dc99', color: 'black', borderRadius: '50%', position: 'absolute', right: '-2', top: '0' }} /> */}
                </div>
              )}{" "}
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: isXs ? "480px" : "700px",
                paddingBottom: "10px",
                paddingTop: "10px",
              }}
            >
              <div style={{ paddingLeft: "10px" }}>
                {seoPages.map((seoPage, index) => (
                  <a
                    href={`/${seoPage.txt_slug
                      .toLowerCase()
                      .replaceAll(" ", "-")}`}
                    key={index}
                    style={{
                      color: "black",
                      textDecoration: "none",
                      fontSize: "16px",
                    }}
                  >
                    <li
                      style={{
                        textIndent: "-1.5rem",
                        paddingLeft: "2rem ",
                        marginBottom: "15px",
                      }}
                      className="listiem"
                    >
                      {seoPage.txt_meta_title}
                    </li>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section7 Web */}
      <Grid
        container
        sx={{
          display: isXs ? "flex" : "none",
          marginTop: "5%",
          display: isXs ? "" : "none",
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <div
            className="d-flex justify-content-center"
            style={{
              position: "relative",
              marginTop: "10%",
              marginBottom: "10%",
            }}
          >
            <img
              src={Frame25}
              alt="Frame24"
              style={{
                position: "absolute",
                width: "78%",
                height: "auto",
                zIndex: 2,
                left: "8.5rem",
                top: "-3rem",
              }}
            />
            <div
              className="d-flex align-items-center"
              style={{
                borderBottomRightRadius: "10px",
                height: "20rem",
                width: "80%",
                backgroundColor: "#F6EBDC",
                borderTopRightRadius: "150px",
                borderBottomLeftRadius: "150px",
              }}
            >
              <div
                style={{
                  maxWidth: "40%",
                  marginLeft: "6vw",
                  marginBottom: "5%",
                }}
              >
                <div style={{ fontSize: "1.5vw", width: "90%" }}>
                  Download the Kanha Home Tuitions Student and Tutor apps now on
                  Google Play Store!
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* Section7 Mobile */}
      <Grid
        container
        sx={{
          display: isXs ? "none" : "",
          marginTop: "5%",
          display: isXs ? "none" : "",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <img
            src={GroupPhone}
            alt="GroupPhone"
            style={{
              position: "absolute",
              width: "50%",
              height: "auto",
              zIndex: 1,
            }}
          />
          <img
            src={PhoneDoodlePlus}
            style={{
              position: "absolute",
              zIndex: 2,
              left: "3rem",
              top: "7rem",
            }}
          />
          <img
            src={PhoneDoodleMultiply}
            style={{
              position: "absolute",
              zIndex: 2,
              right: "3rem",
              top: "8rem",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <div
            className="d-flex justify-content-center"
            style={{
              position: "relative",
              marginTop: "20%",
              marginBottom: "10%",
            }}
          >
            <div
              className="d-flex align-items-center"
              style={{
                borderBottomRightRadius: "10px",
                height: "25rem",
                width: "90%",

                backgroundColor: "#F6EBDC",
                borderTopRightRadius: "50px",
                borderBottomLeftRadius: "50px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  minWidth: "100%",
                  marginTop: "45%",
                  marginBottom: "5%",
                }}
              >
                <div
                  style={{
                    fontSize: "4.2vw",
                    width: "80%",
                    margin: "0 auto",
                    marginTop: "2%",
                    textAlign: "center",
                    marginBottom: "15%",
                  }}
                >
                  Download the Kanha Home Tuitions Student and Tutor apps now on
                  Google Play Store!
                </div>
                <Grid
                  item
                  xs={10}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  xxl={3}
                  container
                  spacing={3}
                  sx={{ margin: "0 auto" }}
                >
                
                </Grid>
                <img
                  src={PhoneDoodleBook}
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    left: "4rem",
                    bottom: "2rem",
                  }}
                />
                <img
                  src={PhoneDoodleDivide}
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    right: "3rem",
                    bottom: "1rem",
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      

      <Footer />

    </>
  );
};
