import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/axios";
import axiosInstanceV2 from "../../../Utils/axiosv2";
import axiosInstanceSM from "../../../Utils/axiosSm";


// import axios from "../../../Utils/axios";
// import axios from "axios";
// import axiosInstance from "../../../Utils/axios";

export const accountPostCreate = createAsyncThunk(
  "admin/postCreate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`accountPostCreate`, data);
      return response.data;
    } catch (error) {
      // Handle errors here if needed
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminDashboardData = createAsyncThunk(
    "admin/dashboardData",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(`adminDashobord`, data);
        return response.data;
      } catch (error) {
        // Handle errors here if needed
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const userLists = createAsyncThunk(
    "admin/userListData",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(`userList`, data);
        return response.data;
      } catch (error) {
        // Handle errors here if needed
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const teacherCreditAddAndRemove = createAsyncThunk(
    "admin/teacherCreditAddAndRemove",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(`creditAction`, data);
        return response.data;
      } catch (error) {
        // Handle errors here if needed
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const studentCloseAndOpenPost = createAsyncThunk(
    "admin/studentCloseAndOpenPost",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(`studentClosePost`, data);
        return response.data;
      } catch (error) {
        // Handle errors here if needed
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );


  
  export const helpSupportDataList = createAsyncThunk(
    "admin/helpSupportDataList",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstanceV2.post(`feedbacks`, data);
        return response.data;
      } catch (error) {
        // Handle errors here if needed
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );


  export const boardListV2 = createAsyncThunk(
    "admin/boards",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstanceSM.post(`api/sm/v1/boards`, data);
        return response.data;
      } catch (error) {
        // Handle errors here if needed
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const classListV2 = createAsyncThunk(
    "admin/classes",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstanceSM.post(`api/sm/v1/classes`, data);
        return response.data;
      } catch (error) {
        // Handle errors here if needed
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const subjectListV2 = createAsyncThunk(
    "admin/subjects",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstanceSM.post(`api/sm/v1/subjects`, data);
        return response.data;
      } catch (error) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const userListsV2 = createAsyncThunk(
    "admin/userListsV2",
    async (data, { rejectWithValue }) => {
      try {
        let pageno=data?.page ?? 1;
        delete data.page;
        let url=  data?.search?.length > 0 ? "userList" : `userList?page=${pageno}`;

        const response = await axiosInstanceV2.post(url, data);
        return response.data;
      } catch (error) {
        // Handle errors here if needed
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const userAccountCloseOpen = createAsyncThunk(
    "admin/userAccountCloseOpen",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(`closeAccount`, data);
        return response.data;
      } catch (error) {
        // Handle errors here if needed
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );



const initialState = {
  creditData:[],
  helpSupportData:[],
  postData: [],
  dashboardData:[],
  userListData:[],
  userAccountCloseOpenData:[],
  loading: false,
  error: null,
};

export const accountPostSlice = createSlice({
  name: "accountPost",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(accountPostCreate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(accountPostCreate.fulfilled, (state, action) => {
      state.loading = false;
      state.postData = action.payload;
      state.error = null;
    });
    builder.addCase(accountPostCreate.rejected, (state, action) => {
      state.loading = false;
      state.postData = null;
      state.error = action.payload;
    });
    builder.addCase(adminDashboardData.pending, (state) => {
        state.loading = true;
      });
    builder.addCase(adminDashboardData.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardData = action.payload;
        state.error = null;
      });
    builder.addCase(adminDashboardData.rejected, (state, action) => {
        state.loading = false;
        state.dashboardData = null;
        state.error = action.payload;
      });
    builder.addCase(userLists.pending, (state) => {
        state.loading = true;
      });
    builder.addCase(userLists.fulfilled, (state, action) => {
        state.loading = false;
        state.userListData = action.payload;
        state.error = null;
      });
    builder.addCase(userLists.rejected, (state, action) => {
        state.loading = false;
        state.userListData = null;
        state.error = action.payload;
      });
      builder.addCase(teacherCreditAddAndRemove.pending, (state) => {
        state.loading = true;
      });
    builder.addCase(teacherCreditAddAndRemove.fulfilled, (state, action) => {
        state.loading = false;
        state.creditData = action.payload;
        state.error = null;
      });
    builder.addCase(teacherCreditAddAndRemove.rejected, (state, action) => {
        state.loading = false;
        state.creditData = null;
        state.error = action.payload;
      });
      builder.addCase(studentCloseAndOpenPost.pending, (state) => {
        state.loading = true;
      });
    builder.addCase(studentCloseAndOpenPost.fulfilled, (state, action) => {
        state.loading = false;
        state.creditData = action.payload;
        state.error = null;
      });
    builder.addCase(studentCloseAndOpenPost.rejected, (state, action) => {
        state.loading = false;
        state.creditData = null;
        state.error = action.payload;
      });
      builder.addCase(helpSupportDataList.pending, (state) => {
        state.loading = true;
      });
    builder.addCase(helpSupportDataList.fulfilled, (state, action) => {
        state.loading = false;
        state.helpSupportData = action.payload;
        state.error = null;
      });
    builder.addCase(helpSupportDataList.rejected, (state, action) => {
        state.loading = false;
        state.helpSupportData = null;
        state.error = action.payload;
      });

      builder.addCase(userListsV2.pending, (state) => {
        state.loading = true;
      });
    builder.addCase(userListsV2.fulfilled, (state, action) => {
        state.loading = false;
        state.userListData = action.payload;
        state.error = null;
      });
    builder.addCase(userListsV2.rejected, (state, action) => {
        state.loading = false;
        state.userListData = null;
        state.error = action.payload;
      });
      builder.addCase(userAccountCloseOpen.pending, (state) => {
        state.loading = true;
      });
    builder.addCase(userAccountCloseOpen.fulfilled, (state, action) => {
        state.loading = false;
        state.userAccountCloseOpenData = action.payload;
        state.error = null;
      });
    builder.addCase(userAccountCloseOpen.rejected, (state, action) => {
        state.loading = false;
        state.userAccountCloseOpenData = null;
        state.error = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { storeMobile} =
accountPostSlice.actions;

export default accountPostSlice.reducer;
