import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from './DashboardStudent/Layout/Layout'
import Home from './DashboardStudent/Home'
import Notifications from './DashboardStudent/Notifications'
import Feedback from './DashboardStudent/Feedback'
import SignupOtp from './AccountStudent/SignupOtp'
import Signup from './AccountStudent/Signup'
import Successfull from './AccountStudent/Successfull'
import Login from './AccountStudent/Login'
import LoginOtp from './AccountStudent/LoginOtp'
import Subject from './InquiryStudent/Subject'
import Location from './InquiryStudent/Location'
import Education from './InquiryStudent/Education'
import SubSubjects from './InquiryStudent/SubSubjects'
import Fee from './InquiryStudent/Fee'
import Classes from './InquiryStudent/Classes'
import Days from './InquiryStudent/Days'
import Start from './InquiryStudent/Start'
import Online from './InquiryStudent/Online'
import Gender from './InquiryStudent/Gender'
import HomeClass from './DashboardStudent/HomeClass'
import LearningNeeds from './DashboardStudent/LearningNeeds'
import AccountProfile from './DashboardStudent/AccountProfile'
import AccountSettings from './DashboardStudent/AccountSettings'
import DashboardHome from './DashboardTeacher/DashboardHome/DashboardHomeEnquiry'
import DashboardHomeStudent from './DashboardTeacher/DashboardHome/DashboardHomeStudent'
import DashboardHomeEnquiryClick from './DashboardTeacher/DashboardHome/DashboardHomeEnquiryClick'
import ProfileInformation from './DashboardTeacher/DashboardProfile/ProfileInformation/ProfileInformation'
import ProfileEdit from './DashboardTeacher/DashboardProfile/ProfileInformation/ProfileEdit'
import ProfileTagline from './DashboardTeacher/DashboardProfile/ProfileInformation/ProfileTagline'
import PhoneNumber from './DashboardTeacher/DashboardProfile/ProfileInformation/PhoneNumber'
import Address from './DashboardTeacher/DashboardProfile/ProfileInformation/Address'
import QualificationExp from './DashboardTeacher/DashboardProfile/ProfileInformation/Qualification&Exp'
import ProfileEducation from './DashboardTeacher/DashboardProfile/ProfileInformation/ProfileEducation'
import Language from './DashboardTeacher/DashboardProfile/ProfileInformation/Language'
import Summary from './DashboardTeacher/DashboardProfile/ProfileInformation/Summary'
import SchoolLocation from './DashboardTeacher/DashboardProfile/ProfileInformation/SchoolLocation'
import Tutoring from './DashboardTeacher/DashboardProfile/ProfileInformation/Tutoring'
import ClassConducted from './DashboardTeacher/DashboardProfile/ClassConducted/ClassConducted'
import PricingClass from './DashboardTeacher/DashboardProfile/Pricing/PricingClass'
import ClassCourse from './DashboardTeacher/DashboardProfile/Pricing/ClassCourse'
import Reviews from './DashboardTeacher/DashboardProfile/Review/Reviews'
import TutorProfile from './DashboardTeacher/DashboardProfile/Profile/TutorProfile'
import CloseAccount from './DashboardTeacher/Account/CloseAccount'
import SubscriptionPlan from './DashboardTeacher/Subscription/SubscriptionPlan'
import SubscriptionHistory from './DashboardTeacher/Subscription/SubscriptionHistory'
import CreditHistory from './DashboardTeacher/Credit/CreditHistory'
import TeacherReviews from './DashboardStudent/TeacherReviews'
import EnquiryDetails from './DashboardStudent/EnquiryDetails'
import CreateReview from './DashboardStudent/CreateReview'
import HelpAndSupport from './DashboardStudent/HelpAndSupport'
import SignupTeacher from './AccountTeacher/SignupTeacher'
import VerifyOtp from './AccountTeacher/VerifyOtp'
import FormTeacher from './AccountTeacher/FormTeacher'
import LoginTeacher from './AccountTeacher/LoginTeacher'
import NotificationTeacher from './DashboardTeacher/NotificationTeacher'
import TermsAndConditions from './TermsAndPrivacy/TermsAndConditions'
import RefundPolicy from './TermsAndPrivacy/RefundPolicy'
import PrivacyPolicyMobile from './TermsAndPrivacy/PrivacyPolicyMobile'
import RefundPolicyMobile from './TermsAndPrivacy/RefundPolicyMobile'
import TermsAndConditionsMobile from './TermsAndPrivacy/TermsAndConditionsMobile'
import UpdateNumberOtp from './AccountTeacher/UpdateNumberOtp'
import AddClass from './DashboardTeacher/DashboardProfile/ClassConducted/AddClass'
import AccountDeleteStudent from './AccountStudent/AccountDeleteStudent'
import AccountDeleteTeacher from './AccountTeacher/AccountDeleteTeacher'
import Landing from './LandingPage/Landing'
import AdminLogin from './AccountAdmin/AdminLogin'
import AdminDashboard from './AccountAdmin/AdminDashboard'
import AdminCreatePost from './AccountAdmin/AdminCreatePost'
import Protected from './Protected'
import StudentList from './AccountAdmin/StudentList'
import TeacherList from './AccountAdmin/TeacherList'
import HelpSupport from './DashboardTeacher/Account/HelpSupport'
import ManageCredit from './AccountAdmin/ManageCredit'
import ManageFeedback from './AccountAdmin/ManageFeedback'
import ManageEnquiry from './AccountAdmin/ManageEnquiry'
import NotFound from './LandingPage/NotFound'
import { HomeTutors, Sitemap } from '../sitemap/Sitemap'
import { HomeTutorsJaipur, SitemapSlug } from '../sitemap/SitemapSlug'
import '../../components/pages/useAnalytics'
import Institute from '../sitemap/Institute'
import ContactUs from '../Navigation/ContactUs'
import Blog from '../Navigation/Blog'
import HowWorkStudent from '../Navigation/ForTeacher'
import ForTeacher from '../Navigation/ForTeacher'
import ForStudent from '../Navigation/ForStudent'
import NavBar from '../Navigation/NavBar'
import StudyMaterial from '../Navigation/StudyMaterial'
import TeacherJobs from '../sitemap/TeacherJobs'
import BoardAndClasses from '../Navigation/BoardAndCourses'
import SubjectsStudyMaterial from '../Navigation/SubjectsStudyMaterial'
import ClassesStudymaterial from '../Navigation/ClassesStudymaterial'
import Notes from '../Navigation/Notes'
import Chapter from '../Navigation/Chapter'
import KathmanduPage from './KathmanduPage'

const PrivateRouting = () => {


  const PrivacyPolicyRedirect = () => {

    React.useEffect(() => {
      window.location.href = '/privacypolicy.html';
    }, []);

    return null;
  };


  return (
    <>

      <BrowserRouter>
        <Routes>
          <Route path='/' exact element={<Landing url="https://kanhahometutions.com/" />}></Route>

          <Route path="/:slug" element={<SitemapSlug />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path='/post-teacher-job' element={<Institute/>}/>
          <Route path='/contact-us' element={<ContactUs/>}/>
          {/* <Route path='/blog' element={<Blog/>}/> */}
          <Route path='/for-teacher' element={<ForTeacher/>}/>
          <Route path='/for-student' element={<ForStudent/>}/>
          <Route path='/navbar' element={<NavBar/>}/>
          <Route path='/study-material' element={<StudyMaterial/>}/>
          <Route path='/study-material-chapter' element={<Chapter/>}/>
          <Route path='/teacher-job' element={<TeacherJobs/>}/>

                    <Route path='/online-coaching-in-kathmandu' element={<KathmanduPage/>}></Route>


          {/* <Route path='/home-tutors-jaipur' exact element={<Signup />}></Route> */}
          {/* <Route path='/home-tutor-jaipur' exact element={<Signup />}></Route> */}
          {/* <Route path='/home-tutions-jaipur' exact element={<Signup />}></Route> */}
          {/* <Route path='/home-tuitions-jaipur' exact element={<Signup />}></Route> */}

          <Route path='/student-signin' exact element={<Signup />}></Route>
          <Route path='/admin-login' exact element={<AdminLogin />}></Route>

          <Route path='/signup-teacher' exact element={<SignupTeacher />}></Route>
          <Route path='/signin-teacher' exact element={<LoginTeacher />}></Route>

          <Route path='/tutor-verify-otp' exact element={<VerifyOtp />}></Route>
          {/* <Route path='/form-teacher'  element={<FormTeacher />}></Route> */}
          <Route path="/form-teacher" element={<Protected Component={FormTeacher} />} />

          <Route path='/signup-otp' exact element={<SignupOtp />}></Route>
          {/* <Route path='/successfull' exact element={<Successfull />}></Route> */}
          <Route path="/successfull" element={<Protected Component={Successfull} />} />

          <Route path='/login' exact element={<Login />}></Route>
          <Route path='/loginOtp' exact element={<LoginOtp />}></Route>
          <Route path='/privacy-policy' exact element={<PrivacyPolicyRedirect />}></Route>
          <Route path='/terms-conditions' exact element={<TermsAndConditions />}></Route>
          <Route path='/refund-policy' exact element={<RefundPolicy />}></Route>
          <Route path='/privacy-policy-mobile' exact element={<PrivacyPolicyMobile />}></Route>
          <Route path='/refund-policy-mobile' exact element={<RefundPolicyMobile />}></Route>
          <Route path='/terms-conditions-mobile' exact element={<TermsAndConditionsMobile />}></Route>


          <Route path='/update-number' exact element={<UpdateNumberOtp />}></Route>
          {/* <Route path='/account-delete-student' exact element={<AccountDeleteStudent />}></Route>
          <Route path='/account-delete-teacher' exact element={<AccountDeleteTeacher />}></Route> */}
          <Route path='/deleteStudentAccount' element={<AccountDeleteStudent />}></Route>
          <Route path='/deleteTutorAccount' element={<AccountDeleteTeacher />}></Route>

          {/* 
          <Route path='/subject' exact element={<Subject />}></Route>
          <Route path='/location' exact element={<Location />}></Route>
          <Route path='/education' exact element={<Education />}></Route>
          <Route path='/subsubjects' exact element={<SubSubjects />}></Route>
          <Route path='/fee' exact element={<Fee />}></Route>
          <Route path='/classes' exact element={<Classes />}></Route>
          <Route path='/days' exact element={<Days />}></Route>
          <Route path='/start' exact element={<Start />}></Route>
          <Route path='/online' exact element={<Online />}></Route>
          <Route path='/gender' exact element={<Gender />}></Route> */}
          {/*  */}
          <Route path="/subject" element={<Protected Component={Subject} />} />
          <Route path="/location" element={<Protected Component={Location} />} />
          <Route path="/education" element={<Protected Component={Education} />} />
          <Route path="/subsubjects" element={<Protected Component={SubSubjects} />} />
          <Route path="/fee" element={<Protected Component={Fee} />} />
          <Route path="/classes" element={<Protected Component={Classes} />} />
          <Route path="/days" element={<Protected Component={Days} />} />
          <Route path="/start" element={<Protected Component={Start} />} />
          <Route path="/online" element={<Protected Component={Online} />} />
          <Route path="/gender" element={<Protected Component={Gender} />} />

          {/*  */}


          <Route path="/dashboard" element={<Layout />}>
            {/* admin route start here */}
            <Route path="/dashboard/admin" element={<Protected Component={AdminDashboard} />} />
            <Route path="/dashboard/createPost" element={<Protected Component={AdminCreatePost} />} />
            <Route path="/dashboard/studentList" element={<Protected Component={StudentList} />} />
            <Route path="/dashboard/teacherList" element={<Protected Component={TeacherList} />} />
            <Route path="/dashboard/manageCredit" element={<Protected Component={ManageCredit} />} />
            <Route path="/dashboard/manageEnquiry" element={<Protected Component={ManageEnquiry} />} />
            <Route path="/dashboard/manageFeedback" element={<Protected Component={ManageFeedback} />} />
            <Route path="/dashboard/board&courses" element={<Protected Component={BoardAndClasses} />} />
            <Route path="/dashboard/board/classes" element={<Protected Component={ClassesStudymaterial} />} />
            <Route path="/dashboard/board/classes/subjects" element={<Protected Component={SubjectsStudyMaterial} />} />
            <Route path="/dashboard/board/classes/subjects/notes" element={<Protected Component={Notes} />} />


            {/* admin route end here */}
            <Route path="/dashboard/home" element={<Protected Component={Home} />} />
            <Route path="/dashboard/homeclass/:itemId" element={<HomeClass />} />
            <Route path="/dashboard/teacherreviews/:teacherId" element={<TeacherReviews />} />
            <Route path="/dashboard/write-review" element={<CreateReview />} />
            <Route path="/dashboard/enquirydetails" element={<EnquiryDetails />} />
            <Route path="/dashboard/learningneeds" element={<Subject />} />
            <Route path="/dashboard/location" element={<Location />} />
            <Route path="/dashboard/education" element={<Education />} />
            <Route path="/dashboard/subsubjects" element={<SubSubjects />} />
            <Route path="/dashboard/classes" element={<Classes />} />
            <Route path='/dashboard/fee' exact element={<Fee />}></Route>
            <Route path='/dashboard/days' exact element={<Days />}></Route>
            <Route path='/dashboard/start' exact element={<Start />}></Route>
            <Route path='/dashboard/online' exact element={<Online />}></Route>
            <Route path='/dashboard/gender' exact element={<Gender />}></Route>
            <Route path="/dashboard/notifications" element={<Notifications />} />
            <Route path="/dashboard/help-support" element={<HelpAndSupport />} />
            <Route path="/dashboard/feedback" element={<Feedback />} />
            <Route path="/dashboard/profile" element={<AccountProfile />} />
            <Route path="/dashboard/settings" element={<AccountSettings />} />

            {/* <Route path="/dashboard/tabhome" element={<TabHome />} /> */}
            {/* teacher route start below */}
            <Route path="/dashboard/dashboard-home" element={<DashboardHome />} />
            <Route path="/dashboard/dashboardhomestudent" element={<DashboardHomeStudent />} />
            <Route path="/dashboard/dashboard-home-enquiry/:itemId" element={<DashboardHomeEnquiryClick />} />
            <Route path="/dashboard/profileinformation" element={<Protected Component={ProfileInformation} />} />

            <Route path="/dashboard/profileedit" element={<Protected Component={ProfileEdit} />} />
            <Route path="/dashboard/profiletagline" element={<ProfileTagline />} />
            <Route path="/dashboard/phonenumber" element={<Protected Component={PhoneNumber} />} />
            <Route path="/dashboard/address" element={<Protected Component={Address} />} />
            <Route path="/dashboard/qualification" element={<Protected Component={QualificationExp} />} />
            <Route path="/dashboard/profileeducation" element={<Protected Component={ProfileEducation} />} />
            <Route path="/dashboard/language" element={<Protected Component={Language} />} />
            <Route path="/dashboard/summary" element={<Protected Component={Summary} />} />
            <Route path="/dashboard/schoollocation" element={<SchoolLocation />} />
            <Route path="/dashboard/tutoring" element={<Protected Component={Tutoring} />} />
            <Route path="/dashboard/classconducted" element={<Protected Component={ClassConducted} />} />
            <Route path="/dashboard/add-class" element={<Protected Component={AddClass} />} />
            <Route path="/dashboard/pricingclass" element={<PricingClass />} />
            <Route path="/dashboard/classcourse" element={<Protected Component={ClassCourse} />} />
            <Route path="/dashboard/reviews" element={<Protected Component={Reviews} />} />
            <Route path="/dashboard/tutorprofile" element={<Protected Component={TutorProfile} />} />
            <Route path="/dashboard/closeaccount" element={<Protected Component={CloseAccount} />} />
            <Route path="/dashboard/support" element={<Protected Component={HelpSupport} />} />
            <Route path="/dashboard/notification" element={<Protected Component={NotificationTeacher} />} />
            <Route path="/dashboard/subscriptionplan" element={<Protected Component={SubscriptionPlan} />} />
            <Route path="/dashboard/subscriptionhistory" element={<Protected Component={SubscriptionHistory} />} />
            <Route path="/dashboard/credithistory" element={<Protected Component={CreditHistory} />} />
          </Route>
          <Route path="*" element={<NotFound />} />

        </Routes>
      </BrowserRouter>
    </>
  )
}

export default PrivateRouting
