import React, { useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';
import NavBar from '../Navigation/NavBar';
import WhatAppMobile from './LandingPage/WhatAppMobile';
import Footer from './LandingPage/Footer';
import user from "../../assets/user.svg";
import img1 from "../../assets/img1.svg";
import img2 from "../../assets/img2.svg";
import doodle from "../../assets/doodle.png";
import posticon from "../../assets/posticon.svg";
import tutoricon from "../../assets/tutoricon.svg";

function KathmanduPage() {
    const isXs = useMediaQuery("(min-width:600px) and (max-width:3000px)");

      useEffect(() => {
        document.title = "Best Online Home Tutor in Kathmandu for IIT JEE, NEET, NDA, 11th, 12th | Kanha Home Tutions";
        let metaDescription = document.querySelector("meta[name='description']");
        if (!metaDescription) {
          metaDescription = document.createElement('meta');
          metaDescription.setAttribute('name', 'description');
          document.head.appendChild(metaDescription);
        }
        metaDescription.setAttribute('content', "Get expert online home tutors in Kathmandu for IIT JEE, NEET, NDA, 11th, and 12th. Our experienced teachers provide personalized online coaching for students of all levels. Find the best online home tutor near you in Kathmandu for maths, physics, chemistry, biology, and other subjects.");
       }, []);  
         
  return (

<>
<WhatAppMobile/>
                          <NavBar/>

      <div
        style={{
          backgroundColor: "#C68863",
          width: "100%",
          height: isXs ? "44.5rem" :'50rem',
          padding: "1.5rem 3rem",
          display: "flex",
          flexDirection: "column",
          gap: isXs ? "4rem" : "2.7rem",
          position: "relative",
          backgroundImage: `url(${doodle})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "jost",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            gap: "1rem",
            marginTop:isXs ?  '80px':'30px'
          }}
        >
          <h1
            style={{
              textAlign: "center",
              marginTop:isXs?'':'1rem',
              fontSize: isXs ? "3rem" : "1.8rem",
              marginBottom:'1rem'
            }}
          >
Online Coaching in Kathmandu
          </h1>


<div style={{fontSize: isXs ? '16px':'18px', width: isXs ? '60%':'80%', textAlign:'center'}}>
Welcome to Kanha Home Tution, your premier online coaching destination in Kathmandu! We offer personalized online tuitions for students of all levels, from 11th to 12th, and for various boards like CBSE, ICSE, and more. Our expert tutors provide one-on-one coaching, ensuring that each student receives individual attention and guidance. 
</div>
        

       
          <span>
          <a href="/student-signin">
          <button
            style={{
              padding: ".8rem 2rem",
              border: "none",
              boxShadow: "4px 8px 4px rgb(0, 0, 0, .1)",
              borderRadius: "5px",
              fontSize: "1.4rem",
              marginTop: isXs? "4rem":"2rem" ,
              fontWeight: "bold",
              backgroundColor: "#FFDB7E",
              color: "#866D2C",
              gap: "40rem",
              width: isXs? '':'22.4rem',
            }}
            type="button"
          >
Post Your Home Tuition or Tutor Requirements
          </button>
        </a>
          </span>

        </div>
        <div
          style={{
            display: isXs ? 'flex' : 'none',
            zIndex: "",
            justifyContent: isXs ? "space-between":'none',
            alignItems: "center",
            position: "absolute",
            gap: isXs ? "40rem" :'0rem',
            top: isXs?"30.7rem":'30.2rem',
            left:isXs ? '':'34px'
          }}
        >
          <img style={{ width: '20%'}} src={img1} alt="" />
          <img style={{ width: '20%', marginTop:'10px' }}  src={img2} alt="" />
        </div>

       
      </div>


      <div style={{padding: isXs ? '40px 70px':'40px 30px', display:'flex',  flexDirection:'column', gap:'12px', marginBottom: isXs ? '90px':'10px'}}>

      <div style={{textAlign:'center', fontSize:'28px', fontWeight:'bold', marginBottom: isXs ?'40px':'20px', marginTop: isXs ? '40px':'20px'}}>Welcome to Kanha Home Tutions - Kathmandu </div>
      <div style={{fontSize:'16px'}}>At Kanha Home Tutions, we understand the importance of personalized online education. That's why we offer online home tutoring services in Kathmandu, providing students with the attention and guidance they need to succeed. Our experienced teachers are dedicated to helping students achieve their academic goals, and our online coaching services are designed to be flexible and convenient. </div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />

      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>Best Online Home Tutor in Kathmandu:</span> </div>
      <div style={{fontSize:'16px'}}>Our experienced teachers are experts in their subjects. </div>
      <div style={{fontSize:'16px'}}>Personalized online attention and guidance for each student. </div>
      <div style={{fontSize:'16px'}}>Flexible scheduling to fit your needs. </div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />

      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>Online Coaching for IIT JEE: </span> </div>
      <div style={{fontSize:'16px'}}>Comprehensive online study material and coaching for IIT JEE entrance exams. </div>
      <div style={{fontSize:'16px'}}>Experienced teachers with proven track record. </div>
      <div style={{fontSize:'16px'}}>Personalized online attention and guidance. </div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />

      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>Online Coaching for NEET: </span> </div>
      <div style={{fontSize:'16px'}}> Expert online guidance for medical entrance exams.</div>
      <div style={{fontSize:'16px'}}> Comprehensive online study material and coaching.  </div>
      <div style={{fontSize:'16px'}}>Regular online progress monitoring and feedback. </div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />

      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>Online Coaching for NDA: </span> </div>
      <div style={{fontSize:'16px'}}> Expert online guidance for defense entrance exams. </div>
      <div style={{fontSize:'16px'}}> Comprehensive online study material and coaching.   </div>
      <div style={{fontSize:'16px'}}> Regular online progress monitoring and feedback.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />

      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>Online Coaching for 11th and 12th: </span> </div>
      <div style={{fontSize:'16px'}}> Comprehensive online study material and coaching for 11th and 12th students. </div>
      <div style={{fontSize:'16px'}}>  Experienced teachers with proven track record. </div>
      <div style={{fontSize:'16px'}}>  Personalized online attention and guidance.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />

      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>Online Coaching for Maths: </span> </div>
      <div style={{fontSize:'16px'}}>Expert online guidance for maths subjects.  </div>
      <div style={{fontSize:'16px'}}>Comprehensive online study material and coaching. </div>
      <div style={{fontSize:'16px'}}>Regular online progress monitoring and feedback. </div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />

      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>Online Coaching for Physics: </span> </div>
      <div style={{fontSize:'16px'}}> Expert online guidance for physics subjects.  </div>
      <div style={{fontSize:'16px'}}> Comprehensive online study material and coaching.  </div>
      <div style={{fontSize:'16px'}}>Regular online progress monitoring and feedback.  </div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />

      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>Online Coaching for Chemistry: </span> </div>
      <div style={{fontSize:'16px'}}>Expert online guidance for chemistry subjects.</div>
      <div style={{fontSize:'16px'}}>Comprehensive online study material and coaching. </div>
      <div style={{fontSize:'16px'}}>Regular online progress monitoring and feedback.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>Online Coaching for Biology: </span> </div>
      <div style={{fontSize:'16px'}}>Expert online guidance for biology subjects. </div>
      <div style={{fontSize:'16px'}}> Comprehensive online study material and coaching. </div>
      <div style={{fontSize:'16px'}}>Regular online progress monitoring and feedback. </div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />


      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>Why Choose Kanha Home Tutions?</span> </div>
      <div style={{fontSize:'16px'}}>Personalized online coaching with experienced teachers  </div>
      <div style={{fontSize:'16px'}}>Flexible scheduling to fit your needs. </div>
      <div style={{fontSize:'16px'}}>Convenient online learning platform. </div>
      <div style={{fontSize:'16px'}}>Expert online guidance and support.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />

            
      <div style={{textAlign:'center', fontSize:'28px', fontWeight:'bold', marginBottom: isXs ?'40px':'20px', marginTop: isXs ? '40px':'20px'}}>Our online coaching services cover a wide range of subjects</div>
      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>Mathematics:</span> </div>
      <div style={{fontSize:'16px'}}>Our mathematics coaching program is designed to help students build a strong foundation in the subject</div>
      <div style={{fontSize:'16px'}}>Our expert tutors provide comprehensive guidance on various topics, including algebra, geometry, trigonometry, and more.</div>
      <div style={{fontSize:'16px'}}>With our online coaching, students can improve their problem-solving skills and achieve academic success.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />

      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>Physics:</span> </div>
      <div style={{fontSize:'16px'}}>Our physics coaching program is tailored to help students understand the fundamental principles of the subject.</div>
      <div style={{fontSize:'16px'}}>Our tutors provide in-depth guidance on various topics, including mechanics, electromagnetism, thermodynamics, and more.</div>
      <div style={{fontSize:'16px'}}>With our online coaching, students can develop a deep understanding of the subject and achieve academic success.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />

      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>Chemistry:</span> </div>
      <div style={{fontSize:'16px'}}>Our chemistry coaching program is designed to help students build a strong foundation in the subject.</div>
      <div style={{fontSize:'16px'}}>Our expert tutors provide comprehensive guidance on various topics, including organic chemistry, inorganic chemistry, physical chemistry, and more. </div>
      <div style={{fontSize:'16px'}}>With our online coaching, students can improve their understanding of the subject and achieve academic success.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />

      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>IIT JEE Coaching:</span> </div>
      <div style={{fontSize:'16px'}}>Our IIT JEE coaching program is designed to help students crack the prestigious exam.</div>
      <div style={{fontSize:'16px'}}>Our expert tutors provide comprehensive guidance and support, covering all aspects of the exam, including mathematics, physics, and chemistry.</div>
      <div style={{fontSize:'16px'}}>With our online coaching, students can improve their chances of getting selected for the IITs.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />

      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>NEET Coaching:</span> </div>
      <div style={{fontSize:'16px'}}>Our NEET coaching program is tailored to help students succeed in the medical entrance exam. </div>
      <div style={{fontSize:'16px'}}>Our tutors provide in-depth guidance on various topics, including biology, chemistry, and physics.</div>
      <div style={{fontSize:'16px'}}>With our online coaching, students can improve their chances of getting selected for medical colleges.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />

      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>NDA Coaching:</span> </div>
      <div style={{fontSize:'16px'}}>Our NDA coaching program is designed to help students prepare for the National Defence Academy exam. </div>
      <div style={{fontSize:'16px'}}>Our expert tutors provide comprehensive guidance and support, covering all aspects of the exam, including mathematics, science, and English.</div>
      <div style={{fontSize:'16px'}}>With our online coaching, students can improve their chances of getting selected for the NDA.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      <div style={{fontSize:'16px'}}><span style={{fontSize:'18px', fontWeight:'500'}}>12th and 11th Coaching:</span> </div>
      <div style={{fontSize:'16px'}}>Our NDA coaching program is designed to help students prepare for the National Defence Academy exam. </div>
      <div style={{fontSize:'16px'}}>Our 12th and 11th coaching programs are designed to help students succeed in their board exams. Our tutors provide personalized guidance and support, covering all subjects, including mathematics, science, and humanities.</div>
      <hr style={{marginTop: '5px',marginBottom: '5px',border: '0',borderTop: '1px solid rgb(96, 94, 94)'}} />
      
      </div>
      

      <Footer />

</>

)
}

export default KathmanduPage